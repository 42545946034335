import {
  Avatar,
  ChatProvider,
  Dialog,
  FlexSpacedColumn,
  RowButtonLinkV2,
  RowContainerV2,
  Text,
  useDialogVisibility,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { createContext, memo, useContext, type ReactNode } from 'react'
import { helpContactDetails } from '../../lib/help-contact-details'
import { openHubspotChat } from '../../lib/hubspot-util'
import HubspotWidgetWrapper from '../organisms/hubspot-widget-wrapper'

const ChatWrapperProvider = createContext<boolean>(false)

export const ChatWrapper = memo<{ children: ReactNode }>(function ChatWrapper({ children }) {
  const { visible, show, hide } = useDialogVisibility('chat')
  const language = useLanguage()

  const isAlreadyWrapped = useContext(ChatWrapperProvider)

  const openChat = languageHasHubspotChat[language] ? openHubspotChat : show

  // The `ChatWrapper` is already added to the root route, but if the root route itself crashes (e.g. wrong search parameters)
  // it's not wrapped. Therefore, we also wrap it in the root error boundary, so it's possible that it's wrapped twice.
  // Therefore, we add this safety layer
  if (isAlreadyWrapped) {
    return children
  }

  return (
    <ChatWrapperProvider.Provider value={true}>
      <ChatProvider value={openChat}>{children}</ChatProvider>
      {visible ? <ChatFallbackDialog onClose={hide} /> : null}
      <HubspotWidgetWrapper />
    </ChatWrapperProvider.Provider>
  )
})

const languageHasHubspotChat: Record<Language, boolean> = {
  fr: true,
  es: false,
}

/**
 * Opens as a fallback when the chat is not available in the current language.
 */
const ChatFallbackDialog = memo<{ onClose: () => void }>(function ChatFallbackDialog({ onClose }) {
  const translate = useTranslate()
  const language = useLanguage()
  const { email, whatsappLink } = helpContactDetails[language]
  return (
    <Dialog
      secondaryActionLabel={translate('close')}
      onSecondaryAction={onClose}
      onClose={onClose}
      size="medium"
      title={translate('chat_fallback_title')}
    >
      <FlexSpacedColumn padding="0">
        <Text>{translate('chat_fallback_explanation')}</Text>
        <RowContainerV2>
          <RowButtonLinkV2
            to={whatsappLink}
            avatarLeft={<Avatar icon="whatsapp-brands" size="30" />}
            primaryText="WhatsApp"
          />
          <RowButtonLinkV2
            to={`mailto:${email}`}
            avatarLeft={<Avatar icon="envelope-regular" size="30" />}
            primaryText={email}
          />
        </RowContainerV2>
      </FlexSpacedColumn>
    </Dialog>
  )
})
