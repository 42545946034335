import type { Language } from '@orus.eu/translations'
import type { Primitive } from 'type-fest'

export const DOCUMENTATION_LANGUAGE: Language = 'fr'

export type ValueDocumentation<TYPE extends Primitive | Primitive[]> = {
  title: string
  detailedExplanationMd?: string
  examples?: TYPE[]
}

type NullableValue = { nullable?: true }

export type StringValueSpecification = NullableValue & {
  type: 'string'
  documentation: ValueDocumentation<string>
  regex?: RegExp | undefined
}

export type BooleanValueSpecification = NullableValue & {
  type: 'boolean'
  documentation: ValueDocumentation<boolean>
}

export type NumberValueSpecification = NullableValue & {
  type: 'number'
  documentation: ValueDocumentation<number>
}

export type EnumValueSpecification<VALUES extends { [key: string]: string }> = NullableValue & {
  type: 'enum'
  documentation: ValueDocumentation<keyof VALUES>
  values: VALUES
}

export type UndocumentedEnumValueSpecification<VALUES extends { [key: string]: null }> = NullableValue & {
  type: 'undocumented-enum'
  documentation: ValueDocumentation<keyof VALUES>
  values: VALUES
}

export type ValueSpecification =
  | StringValueSpecification
  | BooleanValueSpecification
  | NumberValueSpecification
  | EnumValueSpecification<Record<string, string>>
  | UndocumentedEnumValueSpecification<Record<string, null>>

export type ArraySpecification<ITEM extends ValueSpecification> = NullableValue & {
  type: 'array'
  documentation: ValueDocumentation<TypeOfField<ITEM>[]>
  items: ITEM
}

export type FieldSpecification = ValueSpecification | ArraySpecification<ValueSpecification>

export type MessageSpecification = {
  event: string
  descriptionMd: string
  fields: Record<string, FieldSpecification>
}

type TypeOfFieldBuilder<SPEC extends FieldSpecification> =
  SPEC extends ArraySpecification<infer ITEM>
    ? ReadonlyArray<TypeOfField<ITEM>>
    : SPEC extends StringValueSpecification
      ? string
      : SPEC extends BooleanValueSpecification
        ? boolean
        : SPEC extends NumberValueSpecification
          ? number
          : SPEC extends EnumValueSpecification<infer VALUES>
            ? keyof VALUES
            : SPEC extends UndocumentedEnumValueSpecification<infer VALUES>
              ? keyof VALUES
              : never

export type TypeOfField<SPEC extends FieldSpecification> = SPEC extends { nullable: true }
  ? TypeOfFieldBuilder<SPEC> | null
  : TypeOfFieldBuilder<SPEC>

export type TypeOfMessage<MESSAGE extends MessageSpecification> = {
  // top level of message is made of :
  //   - the "type" property discriminating the message type
  //   - the specified fields, specific to this type
  [key in 'event' | keyof MESSAGE['fields']]: key extends keyof MESSAGE['fields']
    ? // type for the specified fields
      TypeOfField<MESSAGE['fields'][key]>
    : // type for the "type" property
      MESSAGE['event']
}

export const m = {
  string(documentation: ValueDocumentation<string>, regex?: RegExp): StringValueSpecification {
    return { type: 'string', documentation, regex }
  },
  boolean(documentation: ValueDocumentation<boolean>): BooleanValueSpecification {
    return { type: 'boolean', documentation }
  },
  number(documentation: ValueDocumentation<number>): NumberValueSpecification {
    return { type: 'number', documentation }
  },
  enum<KEYS extends string>(
    documentation: ValueDocumentation<KEYS>,
    values: { [key in KEYS]: string },
  ): EnumValueSpecification<{ [key in KEYS]: string }> {
    return {
      type: 'enum',
      documentation,
      values,
    }
  },
  enumWithoutValueDescription<KEYS extends string>(
    documentation: ValueDocumentation<KEYS>,
    values: { [key in KEYS]: null },
  ): UndocumentedEnumValueSpecification<{ [key in KEYS]: null }> {
    return {
      type: 'undocumented-enum',
      documentation,
      values,
    }
  },
  nullable<ITEM extends ValueSpecification>(item: ITEM): ITEM & { nullable: true } {
    return {
      ...item,
      nullable: true,
    }
  },
  array<ITEM extends ValueSpecification>(
    documentation: ValueDocumentation<TypeOfField<ITEM>[]>,
    items: ITEM,
  ): ArraySpecification<ITEM> {
    return {
      type: 'array',
      documentation,
      items,
    }
  },
  message<EVENT extends string, FIELDS extends Record<string, FieldSpecification>>(
    event: EVENT,
    descriptionMd: string,
    fields: FIELDS,
  ): {
    event: EVENT
    descriptionMd: string
    fields: FIELDS
  } {
    return {
      event,
      descriptionMd,
      fields,
    }
  },
}
