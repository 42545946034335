import { type } from 'arktype'
import { z } from 'zod'
import type { AbstractDimension } from '../dimension'
import type { SubscriptionCondition, SubscriptionUiElement, SubscriptionUiElementGroup } from '../subscription-ui'

export const allQuoteEditorSectionIds = [
  'contact-data',
  'operating-zone',
  'activity',
  'complementary-activities-selection',
  'product-selection',
  'empty-state-quote',
  'invoicing-configuration',
  'discount',
  'mrph-siret-search',
  'mrpw-siret-search',
  'mrpw-confirm-address',
  'mrpw-add-address',
  'mrph-add-postcode',
  'prequote-form',
  'prequote-revenue',
  'prequote-experience',
  'prequote-insurance',
  'prequote-family-member',
  'prequote-variable',
  'prequote-options',
  'installment-fee',
  'rcda-management-fee',
  'rcph-installment-fee',
  'rcph-management-fee',
  'partner-application-fee',
  'partner-management-fees',
  'partner-fees-card',
  'quote',
  'rcph-cyber-information',
  'post-quote-siret-search',
  'post-quote-confirm-address',
  'post-quote-add-address',
  'start-date',
  'contractualization',
  'statement',
  'additional-personal-data',
  'lock-documents',
  'validate-documents',
  'rcda-global-note',
  'terms',
  'documents',
  'operator',
  'terms',
  'custom-clauses',
  'overcharges',
  'detailed-sheet',
  'overcharges-rcda',
  'manual-exclusion',
] as const

export const quoteEditorSectionIdSchema = z.enum(allQuoteEditorSectionIds)
export type QuoteEditorSectionId = z.infer<typeof quoteEditorSectionIdSchema>

const _quoteEditorTabsTitleType = type(
  "'Tarificateur'| 'Conditions légales'| 'Gestion'| 'Informations'| 'Pièces justificatives' | 'Documents contractuels'",
)

export type QuoteEditorTabsTitle = typeof _quoteEditorTabsTitleType.infer

/**
 * For now, we dont know when a dimension is used to write or read
 * so we create this record to know if a tab is for informative purpose (no writable input) or not
 * hence we can trigger missing inputs error on the right tab
 */
export const hasQuoteEditorWritableInputs: Record<QuoteEditorTabsTitle, boolean> = {
  Tarificateur: true,
  'Conditions légales': false,
  Gestion: true,
  Informations: true,
  'Pièces justificatives': true,
  'Documents contractuels': false,
}

export type QuoteEditorSection = {
  id: QuoteEditorSectionId
  backofficeUi: {
    tab: QuoteEditorTabsTitle
    breadcrumb: string
    /**
     * Title of the step in the BO
     */
    title?: string
    /**
     * Subtitle of the step in the BO
     */
    subtitle?: string
  }
  condition?: SubscriptionCondition
  permission?: string
  subtitleElement?: SubscriptionUiElement
  bodyElements: SubscriptionUiElementGroup
  hideForPartners?: true
  onlyForBrokersSubscription?: true
  onlyForPartnersSubscription?: true
  whenContractSigned?: true
  whenContractNotSigned?: true
  partiallyUsedInputDimensions?: AbstractDimension[]
  hideOnProductionEnv?: true
}
