import { Button, useEnqueueTemporaryNotificationAlert, useTranslate } from '@orus.eu/pharaoh'
import { memo, useEffect, useMemo, useRef, useState, type ReactNode } from 'react'

export const UpdateHandler = memo<{ children: ReactNode }>(function UpdateHandler({ children }) {
  const { enqueueTemporaryNotificationAlert } = useEnqueueTemporaryNotificationAlert()
  const translate = useTranslate()

  const closeUpdateToastRef = useRef<() => void | undefined>()
  const [notificationDisplayed, setNotificationDisplayed] = useState(false)

  const updateButton = useMemo(
    () => (
      <Button
        onClick={() => {
          window.location.reload()
          closeUpdateToastRef.current?.()
        }}
        variant="secondary"
      >
        {translate('force_update_label')}
      </Button>
    ),
    [translate],
  )

  useEffect(() => {
    const handleUpdateSuggested = () => {
      if (!notificationDisplayed) {
        closeUpdateToastRef.current = enqueueTemporaryNotificationAlert(translate('new_webapp_version_available'), {
          variant: 'info',
          extra: updateButton,
        })
        setNotificationDisplayed(true)
      }
    }

    window.addEventListener('update-webapp-suggested', handleUpdateSuggested)

    return () => {
      window.removeEventListener('update-webapp-suggested', handleUpdateSuggested)
    }
  }, [enqueueTemporaryNotificationAlert, updateButton, translate, notificationDisplayed])

  return children
})
