import { ensureError } from '@orus.eu/error'
import { FunctionnalProblemError, InvalidLinkError, NotFoundError, TemporaryProblemError } from '@orus.eu/pharaoh'
import { SearchParamError, type ErrorComponentProps } from '@tanstack/react-router'
import { TRPCClientError } from '@trpc/client'
import { memo } from 'react'
import { logger } from '../../lib/logger'
import { TrpcErrorHandler } from '../../lib/TrpcErrorhandler'
import { CrashPage } from '../organisms/crash-page'
import { GenericProblemMessage } from '../organisms/generic-problem-message'
import { InvalidLinkMessage } from '../organisms/invalid-link-message'
import { NotFound } from '../organisms/not-found'
import TemporaryFailureScreen from '../organisms/temporary-failure-screen'
import { ChatWrapper } from './ChatWrapper'

export const RootErrorBoundary = memo(function RootErrorBoundary(props: ErrorComponentProps) {
  return (
    // The `ChatWrapper` is already added to the root route, but if the root route itself crashes (e.g. wrong search parameters)
    // it's not wrapped. Therefore, we need to wrap it here to have access to the chat
    // Note that the `ChatWrapper` is made to support multiple instances in the tree, so this is safe
    <ChatWrapper>
      <ErrorBoundary {...props} />
    </ChatWrapper>
  )
})

const ErrorBoundary = memo(function ErrorBoundary(props: ErrorComponentProps) {
  const error = ensureError(props.error)

  if (error instanceof TRPCClientError) {
    return <TrpcErrorHandler err={error} />
  }

  if (error instanceof TemporaryProblemError) {
    return <TemporaryFailureScreen />
  }

  if (error instanceof FunctionnalProblemError) {
    return <GenericProblemMessage {...error.params} />
  }

  if (error instanceof NotFoundError || error instanceof SearchParamError) {
    return <NotFound />
  }

  if (error instanceof InvalidLinkError) {
    return <InvalidLinkMessage />
  }

  logger.error(error)
  return <CrashPage />
})
