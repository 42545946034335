import styled from '@emotion/styled'
import { Dialog, FlexColumn, Text, spacing, useTranslate } from '@orus.eu/pharaoh'
import { memo, useCallback, type FunctionComponent } from 'react'

export const ForceUpdateDialog: FunctionComponent = memo(function ForceUpdateDialog() {
  const translate = useTranslate()

  const update = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Dialog
      size="medium"
      title={translate('force_update_title')}
      style="base"
      primaryActionLabel={translate('force_update_label')}
      onPrimaryAction={update}
    >
      <Container>
        <Text variant="body1">{translate('force_update_message')}</Text>
      </Container>
    </Dialog>
  )
})

const Container = styled(FlexColumn)`
  padding-top: ${spacing[60]};
`
