import styled from '@emotion/styled'
import { memo } from 'react'
import { colors } from '../../colors.js'
import { spacing } from '../../foundation/spacing-tokens.js'
import { mobileMediaQuery, useScreenType } from '../../hooks/use-screen-type.js'
import { useUiContext } from '../../hooks/use-screen-variant.js'
import { ContentContainerAppClient } from './container.js'
import { Text } from './text/text.js'

type PageTitleProps = {
  title: string
  appendRightOnDesktop?: React.ReactNode
  fullWidth?: boolean
  hideOnDesktop?: boolean
}

export const PageTitle = memo(function PageTitle(props: PageTitleProps) {
  const { title, appendRightOnDesktop, fullWidth = false, hideOnDesktop = false } = props
  const screenType = useUiContext()

  return hideOnDesktop && screenType === 'desktop' ? null : (
    <Container fullWidth={fullWidth}>
      <StickyOnMobile>
        <Text noWrap variant={screenType === 'desktop' ? 'h4' : 'subtitle2'}>
          {title}
        </Text>
        {screenType === 'desktop' && appendRightOnDesktop}
      </StickyOnMobile>
    </Container>
  )
})

const Container = function Container(props: { children: React.ReactNode; fullWidth?: boolean }) {
  const screenType = useScreenType()
  return screenType === 'desktop' && !props.fullWidth ? (
    <ContentContainerAppClient marginTop={spacing[80]}>{props.children}</ContentContainerAppClient>
  ) : (
    props.children
  )
}

const StickyOnMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mobileMediaQuery} {
    background-color: ${colors.white};
    position: sticky;
    margin-bottom: ${spacing[50]};
    padding: ${spacing[50]} ${spacing[60]};

    top: 0;
    box-shadow: 0 4px 8px -4px rgb(1 4 74 / 12%);
  }
`
