import type { PartialDimensionnedState } from './abstract-dimension'
import type {
  activitiesDimension,
  activitiesDistributionDimension,
  activityDimension,
  companyCreationDateDimension,
  companyIdNumberDimension,
  companyNameDimension,
  estimatedRevenueDimension,
  firstNameDimension,
  lastNameDimension,
  siretDimension,
  workforceDimension,
} from './common-dimensions'
import type { offerInformationDimension } from './quote-offer-information-dimension'
import type {
  rcdaBossExperienceDimension,
  rcdaClaimsCountDimension,
  rcdaClaimsTotalValueDimension,
  rcdaInsurancePeriodStartDateDimension,
  rcdaPreviousInsurersNamesDimension,
} from './rcda'
import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension'
import type {
  decennaleCertificateDocumentDimension,
  experienceDocumentDimension,
  immatriculationDocumentDimension,
  infoReportDocumentDimension,
  otherDocumentDimension,
  proformaInvoiceDocumentDimension,
} from './subscription-document-dimension'
import { TextDimension } from './text-dimension'

export const detailedSheetCompleteStatusDimension = new StringsWithDataEnumDimension({
  name: 'detailedSheetCompleteStatus',
  displayValues: {
    name: 'Statut du dossier',
    placeholder: 'En cours',
  },
  entries: [
    ['pending', { label: 'En cours' }],
    ['accepted', { label: 'Acceptée' }],
    ['rejected', { label: 'Réfusée' }],
  ],
} as const)

export const detailedSheetFirstLevelResponsibleDimension = new TextDimension({
  name: 'detailedSheetFirstLevelResponsible',
  displayValues: {
    name: 'Responsable Niveau 1',
    hint: 'Le superviseur assurance valide l’ensemble du dossier pour garantir sa complétude',
  },
} as const)

export const detailedSheetSecondLevelResponsibleDimension = new TextDimension({
  name: 'detailedSheetSecondLevelResponsible',
  displayValues: {
    name: 'Responsable Niveau 2',
    hint: 'L’analyste assurance vérifie le dossier et les justificatifs pour s’assurer de leur conformité',
  },
} as const)

export const detailedSheetThirdLevelResponsibleDimension = new TextDimension({
  name: 'detailedSheetThirdLevelResponsible',
  displayValues: {
    name: 'Responsable Niveau 3',
    hint: 'L’analyste assurance vérifie le dossier et les justificatifs pour s’assurer de leur conformité',
  },
} as const)

export const detailedSheetExperienceStatusDimension = new StringsWithDataEnumDimension({
  name: 'detailedSheetExperienceStatus',
  displayValues: {
    name: 'Profil du dossier',
  },
  entries: [
    ['other', { label: 'Autre', emoji: '🦉' }],
    ['pioupiou', { label: 'Création de -6 mois SANS antécédent d‘assurance', emoji: '🐣' }],
    ['poussin', { label: 'Création de +6 mois SANS antécédent d‘assurance', emoji: '🐥' }],
    ['coq', { label: 'Création de -3 ans AVEC antécédent d‘assurance', emoji: '🐓' }],
    ['aigle', { label: 'Création de +3 ans AVEC antécédent d‘assurance', emoji: '🦅' }],
  ],
} as const)

export const detailedSheetGlobalSynthesisDimension = new TextDimension({
  name: 'detailedSheetGlobalSynthesis',
  displayValues: { name: 'Synthèse globale', hint: 'Votre synthèse sur le dossier' },
} as const)

export const detailedSheetCustomerSynthesisDimension = new TextDimension({
  name: 'detailedSheetCustomerSynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export const detailedSheetCompanySynthesisDimension = new TextDimension({
  name: 'detailedSheetCompanySynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export const detailedSheetExperienceSynthesisDimension = new TextDimension({
  name: 'detailedSheetExperienceSynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export const detailedSheetActivitySynthesisDimension = new TextDimension({
  name: 'detailedSheetActivitySynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export const detailedSheetHistorySynthesisDimension = new TextDimension({
  name: 'detailedSheetHistorySynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export const detailedSheetOtherSynthesisDimension = new TextDimension({
  name: 'detailedSheetOtherSynthesis',
  displayValues: { name: 'Commentaire', hint: 'Votre commentaire' },
} as const)

export type SummarySectionState = PartialDimensionnedState<
  [
    typeof companyNameDimension,
    typeof activityDimension,
    typeof offerInformationDimension,
    typeof detailedSheetCompleteStatusDimension,
    typeof detailedSheetFirstLevelResponsibleDimension,
    typeof detailedSheetSecondLevelResponsibleDimension,
    typeof detailedSheetThirdLevelResponsibleDimension,
    typeof detailedSheetExperienceStatusDimension,
    typeof detailedSheetCompleteStatusDimension,
    typeof detailedSheetGlobalSynthesisDimension,
  ]
>

export type RepresentativeSectionState = PartialDimensionnedState<
  [typeof firstNameDimension, typeof lastNameDimension, typeof detailedSheetCustomerSynthesisDimension]
>

export type OtherSectionState = PartialDimensionnedState<
  [typeof otherDocumentDimension, typeof detailedSheetOtherSynthesisDimension]
>

export type InsuranceHistorySectionState = PartialDimensionnedState<
  [
    typeof rcdaPreviousInsurersNamesDimension,
    typeof rcdaInsurancePeriodStartDateDimension,
    typeof rcdaClaimsTotalValueDimension,
    typeof rcdaClaimsCountDimension,
    typeof infoReportDocumentDimension,
    typeof decennaleCertificateDocumentDimension,
    typeof detailedSheetHistorySynthesisDimension,
  ]
>

export type CompanySectionState = PartialDimensionnedState<
  [
    typeof siretDimension,
    typeof companyIdNumberDimension,
    typeof companyNameDimension,
    typeof companyCreationDateDimension,
    typeof immatriculationDocumentDimension,
    typeof proformaInvoiceDocumentDimension,
    typeof detailedSheetCompanySynthesisDimension,
  ]
>

export type ExperienceSectionState = PartialDimensionnedState<
  [
    typeof estimatedRevenueDimension,
    typeof workforceDimension,
    typeof rcdaBossExperienceDimension,
    typeof activitiesDistributionDimension,
    typeof activitiesDimension,
    typeof experienceDocumentDimension,
    typeof detailedSheetExperienceSynthesisDimension,
  ]
>

export type ActivitySectionState = PartialDimensionnedState<
  [
    typeof estimatedRevenueDimension,
    typeof workforceDimension,
    typeof activitiesDistributionDimension,
    typeof activitiesDimension,
    typeof experienceDocumentDimension,
    typeof detailedSheetActivitySynthesisDimension,
  ]
>
