export type ErrorOptions<Context extends Jsonable = Jsonable, RequiredContext extends boolean = false> = {
  /**
   * The original cause of the error.
   *
   * It is used when catching and re-throwing an error with a more-specific
   * or useful error message in order to still have access to the original error (and stack).
   */
  cause?: Error
} & (RequiredContext extends true ? { context: Context } : { context?: Context })

export class BaseError<Context extends Jsonable = Jsonable, RequiredContext extends boolean = false> extends Error {
  public readonly context: RequiredContext extends true ? Context : Context | undefined

  constructor(
    ...args: RequiredContext extends true
      ? [message: string, options: ErrorOptions<Context, true>]
      : [message: string, options?: ErrorOptions<Context, false>]
  ) {
    const [message, options] = args
    const { cause, context } = options ?? {}

    super(message, { cause })
    this.name = this.constructor.name

    this.context = context as RequiredContext extends true ? Context : Context | undefined
  }
}

export class TechnicalError<
  Context extends Jsonable = Jsonable,
  RequiredContext extends boolean = false,
> extends BaseError<Context, RequiredContext> {}

export class AbortedError extends BaseError {}

export class AssertionError extends BaseError {}

/**
 * This utility exception can be used to get a compile-time error based on whether a value is
 * never or not.
 * Inspired from workaround solutions of https://github.com/microsoft/TypeScript/issues/38881
 * Remove this when/if the issue is closed some day.
 */
export class UnreachableCaseError extends TechnicalError {
  constructor(value: never) {
    super('Unreachable code reached', { context: value })
  }
}

export class NotYetImplementedError extends TechnicalError {
  constructor() {
    super('Not yet implemented')
  }
}

export class ClientOutdatedError extends BaseError {
  constructor() {
    super('Client version is outdated')
  }
}

export type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | { readonly [key: string]: Jsonable }
  | { toJSON(): Jsonable }

export function checkDefinedAndNotNull<T>(value: T | undefined | null, context: Jsonable = {}): NonNullable<T> {
  if (value == null) {
    throw new TechnicalError('Expected defined and not null value, got null or undefined', { context })
  }
  return value
}
