import { translate } from '@orus.eu/translations'
import {
  AbstractDimension,
  activitiesDimension,
  activitiesDistributionDimension,
  activityAddressCityDimension,
  activityAddressDimension,
  activityAddressPostCodeDimension,
  activityAddressStreetDimension,
  activityDimension,
  activitySearchCategoryDimension,
  activitySpecificAnswersDimension,
  activitySpecificQuestionsDimension,
  birthCityDimension,
  birthCountryDimension,
  birthDateDimension,
  civilLiabilitiesFiveYearsDimension,
  commitmentDimension,
  companyCreationDateDimension,
  companyCreationMaxDateDimension,
  companyIdNumberDimension,
  companyInCreationAllowedDimension,
  companyNameDimension,
  complementaryActivitiesDimension,
  decennaleCertificateDocumentDimension,
  discountDimension,
  documentsLockConfirmedDimension,
  emailDimension,
  esRcphCanadaOrUsaRevenuePercentageDimension,
  esRcphCanadaOrUsaRevenuePercentageRequiredDimension,
  esRcphHeadcountDimension,
  esRcphHeadcountRequiredDimension,
  esRcphHeavySingleCustomerDimension,
  esRcphHeavySingleCustomerRelevantDimension,
  esRcphInstallsSecurityMaterialDimension,
  esRcphIsSecuDimension,
  esRcphMoreThanTwoYearsOfXpDimension,
  esRcphProductDimension,
  esRcphSelectedDimension,
  esRcphVehicleCountDimension,
  esRcphVehicleCountRequiredDimension,
  estimatedRevenueDimension,
  expectedFirstPaymentDateDimension,
  experienceDocumentDimension,
  experiencePizzeriaDocumentDimension,
  firstNameDimension,
  forbiddenMonthlyPaymentDimension,
  generalTermsGroupsDimension,
  globalDocumentNoteDimension,
  hasAcceptedCommercialMessagesDimension,
  hasAcceptedDimension,
  hasMultipleActivitiesDimension,
  hiddenQuoteDimension,
  idDocumentDimension,
  immatriculationDocumentDimension,
  infoReportDocumentDimension,
  installmentFeeRateDimension,
  invoicesDocumentDimension,
  lastNameDimension,
  maxActivitiesCountDimension,
  maxStartDateDimension,
  maxStartDateExplanationDimension,
  minStartDateDimension,
  mrphAssetsClaimsThreeYearsDimension,
  mrphAssetsValueDimension,
  mrphDeductibleDaysDimension,
  mrphFormulaDimension,
  mrphHiscoxActivitiesNamesDimension,
  mrphIndemnityDurationMonthsDimension,
  mrphItAssetsValueDimension,
  mrphOccupationStatusDimension,
  mrphSelectedDimension,
  mrphSurfaceDimension,
  mrpwActivityAddressAllowedWakamCitiesDimension,
  mrpwAssetsClaimsThreeYearsDimension,
  mrpwAssetsValueDimension,
  mrpwHasManagementExperienceInPizzeriaDimension,
  mrpwManagementExperienceInPizzeriaRequiredDimension,
  mrpwOccupationStatusDimension,
  mrpwSelectedDimension,
  mrpwSurfaceDimension,
  mrpwWakamMainActivityDimension,
  mutaBeneficiariesDimension,
  mutaHasBeneficiariesDimension,
  mutaReimbursementBankAccountDimension,
  mutaSelectedDimension,
  mutaSocialSecurityNumberDimension,
  mutaSocialSecurityOrganismNumberDimension,
  mutaSocialSecurityRegimeDimension,
  neededSubscriptionDocumentsDimension,
  offerInformationDimension,
  operatingZoneDimension,
  organizationCanTerminateContractsDimension,
  otherDocumentDimension,
  paymentRecurrenceDimension,
  personalAddressCityDimension,
  personalAddressIsNotActivityAddress,
  personalAddressPostCodeDimension,
  personalAddressStreetDimension,
  phoneDimension,
  placeNameDimension,
  placeSearchQueryDimension,
  placeSearchResultsDimension,
  possibleOffersDimension,
  proformaInvoiceDocumentDimension,
  quoteDimension,
  rcdaAvailableDimension,
  rcdaAxeriaActivityNamesDimension,
  rcdaBossExperienceDimension,
  rcdaClaimsCountDimension,
  rcdaClaimsTotalValueDimension,
  rcdaHasClaimsDimension,
  rcdaHasRevenueLimitExceededDimension,
  rcdaInsurancePeriodStartDateDimension,
  rcdaMaxInsurancePeriodStartDateDimension,
  rcdaPreviouslyInsuredDimension,
  rcdaQuoteDimension,
  rcdaSelectedDimension,
  rcphAvailableDimension,
  rcphCanadaOrUsaRevenuePercentageDimension,
  rcphCyberHighOnlineRevenueDimension,
  rcphCyberManyCreditCardPaymentsDimension,
  rcphHiscoxActivitiesNamesDimension,
  rcphInstallmentFeeRateDimension,
  rcphOptionCyberDefaillanceFournisseurDimension,
  rcphOptionCyberDimension,
  rcphOptionCyberDommagesPeDimension,
  rcphOptionCyberDommagesTiersDimension,
  rcphOptionCyberFraudePiratageDimension,
  rcphOptionTdmiDimension,
  rcphProductDimension,
  rcphProductLabelDimension,
  rcphSelectedDimension,
  ribDocumentDimension,
  riskCarrierProductsDimension,
  siretDimension,
  siretRequiredDimension,
  startDateDimension,
  statementDimension,
  statementWarningDimension,
  terminatePreviousContractDimension,
  terminatePreviousContractRelevantDimension,
  workforceDimension,
  yearlyAccountsDocumentDimension,
  yearlyCommitmentHasDiscountDimension,
} from '../dimension/index.js'
import { quoteDimensions } from './quote-element-dimensions.js'
import { siretSearchSubscriptionBodyElement } from './siret-search-ui-element.js'
import { getConditionDimensions, type SubscriptionCondition } from './subscription-condition.js'
import type { SubscriptionUiElement, SubscriptionUiElementGroup } from './subscription-ui-element.js'
import type { SubscriptionStepId, SubscriptionUiStep } from './subscription-ui-step.js'

export * from './subscription-breadcrumbs.js'

const trackedDimensions: ReadonlyArray<AbstractDimension> = [activityDimension, riskCarrierProductsDimension]

const defaultSideElements: SubscriptionUiElementGroup = [
  {
    type: 'contact-banner',
    dimensions: {
      operatingZone: operatingZoneDimension,
    },
  },
  { type: 'trust' },
]

/**
 * This object describes the whole subscription funnel, and is used by the client-side and
 * server-side subscription framework elements
 *
 * Dimensions used at each steps are inferred from the dimensions declared by the UI element,
 * so it's important that all UI Elements declare the dimensions that they indend to use.
 */
export const subscriptionUiSteps: SubscriptionUiStep[] = [
  //#region Common start of funnel
  {
    id: 'activity-search-main',
    breadcrumbs: ['activity'],
    titleKey: 'subscription_activity-search-main_title',
    hideInBackoffice: true,
    showProgress: true,
    bodyElements: [
      {
        type: 'activity-search-main',
        dimensions: {
          activity: activityDimension,
          operatingZone: operatingZoneDimension,
          complementaryActivities: complementaryActivitiesDimension,
          searchCategory: activitySearchCategoryDimension,
        },
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'activity-category-search',
    condition: { and: [{ defined: activitySearchCategoryDimension }, { not: { defined: activityDimension } }] },
    breadcrumbs: ['activity'],
    title: 'Quelle activité souhaitez-vous couvrir ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'activity-category-search',
        dimensions: {
          activity: activityDimension,
          operatingZone: operatingZoneDimension,
          searchCategory: activitySearchCategoryDimension,
        },
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'general-information',
    condition: { and: [{ not: mrpwSelectedDimension }, { notEmpty: activitySpecificQuestionsDimension }] },
    breadcrumbs: ['activity'],
    title: 'Dites-nous en plus sur votre activité',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'activity-specific-questions',
        dimensions: {
          activity: activityDimension,
          activitySpecificQuestions: activitySpecificQuestionsDimension,
          answers: activitySpecificAnswersDimension,
        },
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'complementary-activities-selection',
    condition: {
      and: [{ or: [rcdaAvailableDimension, rcphAvailableDimension] }, { defined: possibleOffersDimension }],
    },
    breadcrumbs: ['activity'],
    title: "Exercez-vous d'autres activités ?",
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'activities-selector',
        dimensions: {
          operatingZone: operatingZoneDimension,
          activity: activityDimension,
          complementaryActivities: complementaryActivitiesDimension,
          maxActivitiesCount: maxActivitiesCountDimension,
          possibleOffers: possibleOffersDimension,
          rcphProduct: rcphProductDimension,
          mrphHiscoxActivitiesNames: mrphHiscoxActivitiesNamesDimension,
          rcphHiscoxActivitiesNames: rcphHiscoxActivitiesNamesDimension,
          rcphProductLabel: rcphProductLabelDimension,
          mrpwWakamMainActivity: mrpwWakamMainActivityDimension,
          rcdaAxeriaActivityNames: rcdaAxeriaActivityNamesDimension,
        },
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion

  //#region MRPW siret steps
  {
    id: 'mrpw-siret-search',
    condition: {
      and: [mrpwSelectedDimension, { defined: placeSearchQueryDimension }],
    },
    breadcrumbs: ['activity'],
    title: 'Trouvons ensemble votre établissement',
    showProgress: true,
    bodyElements: [siretSearchSubscriptionBodyElement],
    sideElements: defaultSideElements,
  },
  {
    id: 'mrpw-confirm-address',
    condition: { and: [mrpwSelectedDimension, { defined: activityAddressDimension }] },
    breadcrumbs: ['activity'],
    title: 'Les informations de votre établissement',
    showProgress: true,
    bodyElements: [
      {
        type: 'address-map',
        dimension: activityAddressDimension,
        hideInSelfOnboarding: true,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'product-indicator',
        product: 'mrpw',
        hideInBackoffice: true,
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          esRcphSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphProductDimension,
        },
      },
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'choice',
        variant: 'select',
        dimensions: {
          value: activityAddressCityDimension,
          allowedValuesResult: mrpwActivityAddressAllowedWakamCitiesDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', hideInBackoffice: true },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension },
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  {
    id: 'mrpw-add-address',
    condition: { and: [mrpwSelectedDimension, { not: { defined: activityAddressDimension } }] },
    breadcrumbs: ['activity'],
    title: 'Ajouter votre établissement',
    showProgress: true,
    bodyElements: [
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'choice',
        variant: 'select',
        dimensions: {
          value: activityAddressCityDimension,
          allowedValuesResult: mrpwActivityAddressAllowedWakamCitiesDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', hideInBackoffice: true },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension },
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  //#endregion MRPW siret steps
  //#region RCDA pre-revenue funnel
  {
    id: 'rcda-company-data',
    condition: rcdaSelectedDimension,
    breadcrumbs: ['insurance'],
    title: 'Vos informations d’entreprise',
    showProgress: true,
    bodyElements: [
      {
        type: 'product-indicator',
        product: 'rcda',
        hideInBackoffice: true,
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          esRcphSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphProductDimension,
        },
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'integer',
        dimension: workforceDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension, maxValue: companyCreationMaxDateDimension },
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion RCDA pre-revenue funnel
  //#region MRPW pre-revenue funnel
  {
    id: 'mrpw-occupation-status',
    condition: mrpwSelectedDimension,
    breadcrumbs: ['activity'],
    title: 'Quel est le statut d’occupation de votre local ?',
    hideInBackoffice: true,
    showProgress: true,
    bodyElements: [
      {
        type: 'choice',
        // make a more generic variant if a second grid appears
        variant: 'one-click-occupation-status-grid',
        dimension: mrpwOccupationStatusDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrpwSelectedDimension,
    id: 'mrpw-assets-value',
    breadcrumbs: ['activity'],
    title: 'Vos informations sur votre local',
    hideInBackoffice: true,
    showProgress: true,
    bodyElements: [
      {
        type: 'surface',
        dimension: mrpwSurfaceDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du contenu du local',
        },
        dimension: mrpwAssetsValueDimension,
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion MRPW pre-revenue funnel
  //#region RCPH pre-quote funnel
  {
    id: 'rcph-prequote-form',
    condition: rcphSelectedDimension,
    breadcrumbs: ['insurance'],
    title: 'Vos informations d’entreprise',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'product-indicator',
        product: 'rc-pro',
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          esRcphSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphProductDimension,
        },
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        displayValues: {
          hint: 'Domiciliation de votre établissement uniquement en France métropolitaine',
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
          hint: 'Prévisionnel HT sur les 12 prochains mois, montant révisable en cours de contrat 🙂. Cela nous permet de vous proposer le tarif le plus adapté',
        },
        dimension: estimatedRevenueDimension,
        layout: { width: 'narrow' },
      },
      { type: 'separator' },
      {
        type: 'subtitle',
        text: 'Vos antécédents d’assurance',
      },
      {
        type: 'choice',
        dimension: civilLiabilitiesFiveYearsDimension,
        label: 'Vous avez déjà été mis en cause lors d’un sinistre en RC Pro ces 5 dernières années.',
        variant: 'checkbox',
        defaultValue: '0',
      },
      {
        type: 'financial-rate',
        variant: 'boolean',
        label: 'Vous faites plus de 30 % de votre chiffre d’affaires aux États-unis ou au Canada.',
        dimension: rcphCanadaOrUsaRevenuePercentageDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'rcph-activities-distribution',
    condition: {
      and: [rcphAvailableDimension, hasMultipleActivitiesDimension, { defined: estimatedRevenueDimension }],
    },
    hideInBackoffice: true,
    breadcrumbs: ['activity'],
    title: 'Quelle est la répartition de vos activités ?',
    showProgress: true,
    bodyElements: [
      {
        type: 'activities-distribution',
        dimensions: {
          activities: activitiesDimension,
          activitiesDistribution: activitiesDistributionDimension,
          estimatedRevenue: estimatedRevenueDimension,
        },
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion RCPH pre-quote funnel
  //#region ES-RCPH pre-quote funnel
  {
    id: 'es-rcph-prequote-form',
    condition: esRcphSelectedDimension,
    breadcrumbs: ['insurance'],
    title: translate('subscription_funnel_es_rcph_prequote_form_title', 'es'),
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'product-indicator',
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          esRcphSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphProductDimension,
        },
      },

      /*
      For not it doesn't seem we need postcode for the quote, so let's skip it for now
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        displayKeys: {
          hint: 'subscription_funnel_es_rcph_prequote_form_postcode',
        },
        layout: { width: 'narrow' },
      },*/
      {
        type: 'amount',
        dimension: estimatedRevenueDimension,
        displayKeys: {
          hint: 'subscription_funnel_es_rcph_prequote_form_estimated_revenue_hint',
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'financial-rate',
        dimension: esRcphCanadaOrUsaRevenuePercentageDimension,
        variant: 'direct',
        layout: { width: 'narrow' },
        condition: { and: [esRcphCanadaOrUsaRevenuePercentageRequiredDimension] },
      },
      { type: 'separator' },
      {
        type: 'positive-integer',
        dimension: esRcphHeadcountDimension,
        condition: esRcphHeadcountRequiredDimension,
      },
      {
        type: 'integer',
        dimension: esRcphVehicleCountDimension,
        condition: esRcphVehicleCountRequiredDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'es-rcph-heavy-single-customer',
    condition: { and: [esRcphSelectedDimension, esRcphHeavySingleCustomerRelevantDimension] },
    breadcrumbs: ['insurance'],
    title: translate('subscription_funnel_es_rcph_heavy_single_customer_title', 'es'),
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: esRcphHeavySingleCustomerDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'es-rcph-xp',
    condition: { and: [esRcphSelectedDimension, esRcphIsSecuDimension] },
    breadcrumbs: ['insurance'],
    title: translate('subscription_funnel_es_rcph_xp_title', 'es'),
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: esRcphMoreThanTwoYearsOfXpDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'es-rcph-installs-security-material',
    condition: { and: [esRcphSelectedDimension, esRcphIsSecuDimension] },
    breadcrumbs: ['insurance'],
    title: translate('subscription_funnel_es_rcph_installs_security_material_title', 'es'),
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        dimension: esRcphInstallsSecurityMaterialDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion ES-RCPH pre-quote funnel
  //#region MRPH pre-quote funnel
  {
    id: 'mrph-rc-claims',
    breadcrumbs: ['insurance'],
    title: 'Votre responsabilité civile professionnelle a-t-elle été mise en cause ces 5 dernières années ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        label: 'Nombre de sinistres en RC pro',
        dimension: civilLiabilitiesFiveYearsDimension,
      },
      { type: 'call-to-honesty-banner', hideInBackoffice: true },
    ],
    condition: mrphSelectedDimension,
    sideElements: defaultSideElements,
  },
  //#endregion MRPH pre-quote funnel
  //#region MUTA pre-quote funnel
  {
    id: 'muta-personal-data',
    condition: mutaSelectedDimension,
    breadcrumbs: ['insurance'],
    title: 'À propos de vous ?',
    hideInBackoffice: true,
    showProgress: true,
    bodyElements: [
      {
        type: 'calendar-date',
        dimensions: { value: birthDateDimension },
        layout: { width: 'narrow' },
      },
      {
        type: 'postcode',
        dimension: personalAddressPostCodeDimension,
        displayValues: {
          label: 'Code postal de résidence',
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'choice',
        variant: 'radio-group-table',
        dimension: mutaSocialSecurityRegimeDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'muta-beneficiary-question',
    condition: mutaSelectedDimension,
    hideInBackoffice: true,
    breadcrumbs: ['insurance'],
    title: 'Les membres de votre famille',
    showProgress: true,
    bodyElements: [
      {
        type: 'boolean',
        dimension: mutaHasBeneficiariesDimension,
        variant: 'yes-no-buttons',
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'muta-beneficiaries',
    hideInBackoffice: true,
    condition: mutaHasBeneficiariesDimension,
    breadcrumbs: ['insurance'],
    title: 'Les membres de votre famille',
    showProgress: true,
    bodyElements: [
      {
        type: 'add-beneficiaries',
        dimension: mutaBeneficiariesDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion MUTA pre-quote funnel
  {
    id: 'revenue',
    breadcrumbs: ['insurance'],
    condition: { or: [mrpwSelectedDimension, mrphSelectedDimension, rcdaSelectedDimension] },
    title: 'Votre chiffre d’affaires',
    hideInBackoffice: true,
    showProgress: true,
    bodyElements: [
      {
        type: 'product-indicator',
        product: 'mrph',
        hideInBackoffice: true,
        dimensions: {
          possibleOffersDimension,
          mrpwSelectedDimension,
          mrphSelectedDimension,
          rcphSelectedDimension,
          mutaSelectedDimension,
          rcdaSelectedDimension,
          esRcphSelectedDimension,
          rcphOptionCyberDimension,
          rcphOptionTdmiDimension,
          riskCarrierProductsDimension,
          esRcphProductDimension,
        },
      },
      {
        type: 'amount',
        displayValues: {
          label: 'CA HT estimé des 12 prochains mois',
        },
        dimension: estimatedRevenueDimension,
      },
      {
        type: 'callout',
        condition: rcdaSelectedDimension,
        text: 'Chiffre d’affaires de vos activités déclarées (y compris celles que vous sous-traitez), dont prestations de services et vente de marchandises et de produits fabriqués.',
        hideInBackoffice: true,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#region RCDA post-revenue funnel
  {
    id: 'rcda-previously-insured',
    condition: rcdaSelectedDimension,
    breadcrumbs: ['insurance'],
    title: 'Votre entreprise est-elle actuellement assurée en décennale ?',
    showProgress: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        disableSubmit: true,
        hideLabel: true,
        dimension: rcdaPreviouslyInsuredDimension,
      },
      {
        type: 'separator',
        condition: rcdaPreviouslyInsuredDimension,
      },
      {
        type: 'subtitle',
        text: 'Votre historique d’assurance',
        condition: rcdaPreviouslyInsuredDimension,
      },
      {
        type: 'calendar-date',
        dimensions: {
          value: rcdaInsurancePeriodStartDateDimension,
          maxValue: rcdaMaxInsurancePeriodStartDateDimension,
        },
        layout: { width: 'narrow' },
        condition: rcdaPreviouslyInsuredDimension,
      },
      {
        type: 'integer',
        dimension: rcdaClaimsCountDimension,
        layout: { width: 'narrow' },
        condition: rcdaPreviouslyInsuredDimension,
      },
      {
        type: 'amount',
        dimension: rcdaClaimsTotalValueDimension,
        condition: { and: [rcdaPreviouslyInsuredDimension, rcdaHasClaimsDimension] },
        layout: { width: 'narrow' },
      },
    ],
    hideInBackoffice: true,
    sideElements: defaultSideElements,
  },
  {
    id: 'rcda-experience',
    condition: rcdaSelectedDimension,
    breadcrumbs: ['insurance'],
    title: 'Combien d’années d’expérience avez-vous sur votre activité ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        dimension: rcdaBossExperienceDimension,
      },
      {
        type: 'callout',
        text: 'Des documents justificatifs (diplômes, certificats de travail et fiches de paye) seront demandés pour valider votre souscription. Ils doivent démontrer au minimum de 1 an d’activité sur les 3 dernières années.',
        hideInBackoffice: true,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion RCDA post-revenue funnel
  //#region MRPH pre-siret funnel
  {
    condition: mrphSelectedDimension,
    id: 'mrph-claims',
    breadcrumbs: ['insurance'],
    hideInBackoffice: true,
    title:
      'Avez-vous déclaré des sinistres sur vos locaux professionnels ou leur contenu durant les 3 dernières années ?',
    showProgress: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'one-click-submit-rows',
        hint: 'Le nombre de fois où vos locaux ou leur contenu ont subi des dommages que votre assureur vous a remboursés : dégâts des eaux, bris de glace, incendies, etc.',
        dimension: mrphAssetsClaimsThreeYearsDimension,
      },
      { type: 'call-to-honesty-banner', hideInBackoffice: true },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrphSelectedDimension,
    id: 'mrph-occupation-status',
    breadcrumbs: ['insurance'],
    title: 'Quel est le statut d’occupation de votre local ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'choice',
        // make a more generic variant if a second grid appears
        variant: 'one-click-occupation-status-grid',
        dimension: mrphOccupationStatusDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrphSelectedDimension,
    id: 'mrph-surface',
    breadcrumbs: ['insurance'],
    title: 'Quelle est la superficie de votre local ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'surface',
        dimension: mrphSurfaceDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrphSelectedDimension,
    id: 'mrph-assets-value',
    breadcrumbs: ['insurance'],
    title: 'Quelle est la valeur du contenu de vos locaux ?',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du contenu (€)',
        },
        dimension: mrphAssetsValueDimension,
      },
      {
        type: 'amount',
        displayValues: {
          label: 'Valeur du matériel informatique (€)',
        },
        dimension: mrphItAssetsValueDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrphSelectedDimension,
    id: 'mrph-formula',
    breadcrumbs: ['insurance'],
    title: 'Choisissez votre niveau de protection multirisque',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'radio-button-cards',
        dimension: mrphFormulaDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    condition: mrphSelectedDimension,
    id: 'mrph-option-pe',
    breadcrumbs: ['insurance'],
    showProgress: true,
    hideInSelfOnboarding: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'select',
        dimension: mrphDeductibleDaysDimension,
      },
      {
        type: 'choice',
        variant: 'select',
        dimension: mrphIndemnityDurationMonthsDimension,
      },
    ],
  },
  //#endregion MRPH pre-siret funnel
  {
    id: 'mrph-siret-search',
    condition: {
      and: [mrphSelectedDimension, { defined: placeSearchQueryDimension }],
    },
    breadcrumbs: ['insurance'],
    title: 'Trouvons ensemble votre établissement',
    showProgress: true,
    bodyElements: [siretSearchSubscriptionBodyElement],
    sideElements: defaultSideElements,
  },
  //#region MRPW funnel
  {
    condition: mrpwSelectedDimension,
    id: 'mrpw-claims',
    breadcrumbs: ['insurance'],
    title: 'Avez-vous déjà subi des sinistres au cours des 3 dernières années ?',
    showProgress: true,
    bodyElements: [
      {
        type: 'choice',
        variant: 'radio-group-table-with-title',
        label: 'Nombre de sinistres aux biens',
        dimension: mrpwAssetsClaimsThreeYearsDimension,
      },
      {
        type: 'call-to-honesty-banner',
        hideInBackoffice: true,
      },
      {
        type: 'separator',
        hideInBackoffice: true,
        condition: {
          and: [
            mrpwSelectedDimension,
            { notEmpty: activitySpecificQuestionsDimension },
            { defined: mrpwAssetsClaimsThreeYearsDimension },
          ],
        },
      },
      {
        type: 'subtitle',
        text: 'Êtes-vous dans une de ces situations ? ',
        hideInBackoffice: true,
        condition: {
          and: [
            mrpwSelectedDimension,
            { notEmpty: activitySpecificQuestionsDimension },
            { defined: mrpwAssetsClaimsThreeYearsDimension },
          ],
        },
      },
      {
        type: 'activity-specific-questions',
        condition: {
          and: [
            mrpwSelectedDimension,
            { notEmpty: activitySpecificQuestionsDimension },
            { defined: mrpwAssetsClaimsThreeYearsDimension },
          ],
        },
        dimensions: {
          activity: activityDimension,
          activitySpecificQuestions: activitySpecificQuestionsDimension,
          answers: activitySpecificAnswersDimension,
        },
        hideInBackoffice: true,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#endregion MRPW funnel
  //#region MRPH post-siret funnel
  {
    id: 'mrph-confirm-address',
    condition: { and: [mrphSelectedDimension, { defined: activityAddressDimension }] },
    breadcrumbs: ['insurance'],
    title: 'Vos informations d’entreprise',
    showProgress: true,
    bodyElements: [
      {
        type: 'address-map',
        dimension: activityAddressDimension,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', hideInBackoffice: true },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension },
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  {
    id: 'mrph-add-address',
    condition: { and: [mrphSelectedDimension, { not: { defined: activityAddressDimension } }] },
    breadcrumbs: ['insurance'],
    title: 'Ajouter votre établissement',
    showProgress: true,
    bodyElements: [
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: placeNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', hideInBackoffice: true },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension },
        layout: { width: 'narrow' },
      },
    ],
    sideElements: defaultSideElements,
    partiallyUsedInputDimensions: [companyIdNumberDimension],
  },
  //#endregion MRPH post-siret funnel
  {
    id: 'rcph-cyber-information',
    condition: { and: [rcphSelectedDimension, rcphOptionCyberDimension] },
    breadcrumbs: ['insurance'],
    title: 'Précisons votre protection cyber. Votre entreprise :',
    showProgress: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'fait plus de 25 % de son chiffre d’affaires en ligne' },
        dimension: rcphCyberHighOnlineRevenueDimension,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'réalise plus de 100 000 paiements par CB en ligne' },
        dimension: rcphCyberManyCreditCardPaymentsDimension,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'pourrait être bloquée en cas de cyberattaques' },
        dimension: rcphOptionCyberDommagesPeDimension,
        hideInBackoffice: true,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'verrait sa chaine de production affectée en cas de cyberattaques' },
        dimension: rcphOptionCyberDefaillanceFournisseurDimension,
        hideInBackoffice: true,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'dispose de données critiques et confidentielles' },
        dimension: rcphOptionCyberDommagesTiersDimension,
        hideInBackoffice: true,
      },
      {
        type: 'boolean',
        variant: 'checkbox-card',
        displayValues: { label: 'est exposée à la cyber-fraude ou au piratage téléphonique' },
        dimension: rcphOptionCyberFraudePiratageDimension,
        hideInBackoffice: true,
      },
    ],
    sideElements: defaultSideElements,
    nextStepLabelTip: 'Vous pouvez aussi continuer sans faire de choix',
  },
  {
    id: 'mrpw-pizzeria-mgt-xp',
    condition: mrpwManagementExperienceInPizzeriaRequiredDimension,
    breadcrumbs: ['insurance'],
    title: 'Votre expérience en tant que gérant de pizzeria',
    showProgress: true,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'yes-no-buttons',
        displayValues: {
          label: "J'ai un an ou plus d'expérience en tant que gérant d'une pizzeria",
        },
        dimension: mrpwHasManagementExperienceInPizzeriaDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  //#region Common quote steps
  {
    id: 'personal-data',
    breadcrumbs: ['insurance'],
    /*
     * The title was originally 'Vos coordonnées professionnelles', which is moved
     * within the PersonalDataOverQuoteSubscriptionUiElementBlock component
     * but because the following field is used below the blur, it shall be
     * identical to the quote's
     */
    titleKey: 'subscription_funnel_your_quote',
    showProgress: true,
    hideForExistingCustomers: true,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'personal-data-over-quote-modal',
        dimensions: {
          email: emailDimension,
          phone: phoneDimension,
          hasAccepted: hasAcceptedDimension,
          hasAcceptedCommercialMessages: hasAcceptedCommercialMessagesDimension,
          operatingZone: operatingZoneDimension,
        },
      },
      {
        type: 'quote-page-body',
        dimensions: quoteDimensions,
        condition: {
          and: [
            { defined: hiddenQuoteDimension },
            { defined: offerInformationDimension },
            { defined: paymentRecurrenceDimension },
            { defined: commitmentDimension },
            { defined: discountDimension },
            { defined: yearlyCommitmentHasDiscountDimension },
            { defined: activityDimension },
            { defined: riskCarrierProductsDimension },
          ],
        },
        sendTrackingEvents: false,
        tracking: {
          dimensions: {
            activity: activityDimension,
            riskCarrierProducts: riskCarrierProductsDimension,
            hiddenQuote: hiddenQuoteDimension,
          },
        },
      },
    ],
    sideElements: [
      {
        type: 'price-card',
        showDetails: true,
        showSubscribeButton: true,
        condition: {
          and: [
            { defined: hiddenQuoteDimension },
            { defined: offerInformationDimension },
            { defined: paymentRecurrenceDimension },
            { defined: commitmentDimension },
            { defined: discountDimension },
            { defined: yearlyCommitmentHasDiscountDimension },
            { defined: activityDimension },
            { defined: riskCarrierProductsDimension },
          ],
        },
        dimensions: {
          hiddenQuote: hiddenQuoteDimension,
          email: emailDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          paymentRecurrence: paymentRecurrenceDimension,
          commitment: commitmentDimension,
          discount: discountDimension,
          offerInformation: offerInformationDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          operatingZone: operatingZoneDimension,
        },
      },
    ],
    nextStepLabelKey: 'subscription_funnel_quote_see_my_quote',
  },
  {
    id: 'quote',
    condition: {
      and: [
        { defined: quoteDimension },
        { defined: offerInformationDimension },
        { defined: paymentRecurrenceDimension },
        { defined: commitmentDimension },
        { defined: discountDimension },
        { defined: yearlyCommitmentHasDiscountDimension },
        { defined: activityDimension },
        { defined: riskCarrierProductsDimension },
      ],
    },
    breadcrumbs: ['quote', 'personalized_offer'],
    titleKey: 'subscription_funnel_your_quote',
    showProgress: false,
    subtitleElement: {
      type: 'quote-page-subtitle',
      dimensions: { activity: activityDimension, complementaryActivities: complementaryActivitiesDimension },
    },
    bodyElements: [
      {
        type: 'quote-page-body',
        dimensions: quoteDimensions,
        sendTrackingEvents: true,
        tracking: {
          dimensions: {
            activity: activityDimension,
            riskCarrierProducts: riskCarrierProductsDimension,
            hiddenQuote: hiddenQuoteDimension,
          },
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
    sideElements: [
      {
        type: 'price-card',
        showDetails: true,
        showSubscribeButton: true,
        dimensions: {
          hiddenQuote: hiddenQuoteDimension,
          email: emailDimension,
          paymentRecurrence: paymentRecurrenceDimension,
          commitment: commitmentDimension,
          discount: discountDimension,
          offerInformation: offerInformationDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          operatingZone: operatingZoneDimension,
        },
      },
    ],
    sideElementsMobileVariant: 'card',
    details: {
      guarantees: [
        {
          type: 'guarantee-detail',
          dimensions: {
            offerInformation: offerInformationDimension,
            operatingZone: operatingZoneDimension,
          },
        },
      ],
    },
  },
  {
    id: 'coverage',
    breadcrumbs: ['quote', 'coverage'],
    titleKey: 'subscription_funnel_coverage_title',
    showProgress: false,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'payment-recurrence',
        dimensions: {
          value: paymentRecurrenceDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          startDate: startDateDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          mrpwSelected: mrpwSelectedDimension,
          rcdaSelected: rcdaSelectedDimension,
          rcphSelected: rcphSelectedDimension,
          quote: quoteDimension,
          installmentFeeRate: installmentFeeRateDimension,
          rcphInstallmentFeeRate: rcphInstallmentFeeRateDimension,
        },
      },
      { type: 'separator' },
      {
        type: 'calendar-date',
        size: 'large',
        dimensions: {
          value: startDateDimension,
          minValue: minStartDateDimension,
          maxValue: maxStartDateDimension,
          maxValueExplanation: maxStartDateExplanationDimension,
        },
      },
      {
        type: 'separator',
        condition: {
          and: [organizationCanTerminateContractsDimension, terminatePreviousContractRelevantDimension],
        },
      },
      {
        type: 'boolean',
        dimension: terminatePreviousContractDimension,
        variant: 'checkbox-with-title',
        displayKeys: {
          label: 'subscription_funnel_coverage_terminate_previous_contract_label',
          hint: 'subscription_funnel_coverage_terminate_previous_contract_hint',
        },
        condition: { and: [organizationCanTerminateContractsDimension, terminatePreviousContractRelevantDimension] },
      },
    ],
    sideElements: [
      {
        type: 'price-card',
        showDetails: false,
        showSubscribeButton: false,
        dimensions: {
          hiddenQuote: hiddenQuoteDimension,
          email: emailDimension,
          paymentRecurrence: paymentRecurrenceDimension,
          commitment: commitmentDimension,
          discount: discountDimension,
          offerInformation: offerInformationDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          operatingZone: operatingZoneDimension,
        },
      },
    ],
    sideElementsMobileVariant: 'hidden',
  },
  {
    id: 'post-quote-rcda-revenue-data',
    condition: {
      and: [
        rcdaSelectedDimension,
        { defined: activitiesDimension },
        { defined: activitiesDistributionDimension },
        { defined: estimatedRevenueDimension },
      ],
    },
    breadcrumbs: ['quote', 'activity_split'],
    title: 'En savoir plus sur votre activité',
    showProgress: false,
    bodyElements: [
      {
        type: 'subtitle',
        text: 'Mon entreprise dépasse l’un des seuils ci-dessous',
      },
      {
        type: 'rcda-revenue-limit',
        dimensions: {
          activities: activitiesDimension,
          activitiesDistribution: activitiesDistributionDimension,
          estimatedRevenue: estimatedRevenueDimension,
        },
      },
      {
        type: 'boolean',
        dimension: rcdaHasRevenueLimitExceededDimension,
        variant: 'yes-no-buttons',
        hideLabel: true,
      },
    ],
    sideElements: defaultSideElements,
    hideInBackoffice: true,
  },
  {
    id: 'statement',
    condition: { and: [{ defined: statementDimension }, { defined: statementWarningDimension }] },
    breadcrumbs: ['wrap_up', 'statement'],
    titleKey: 'subscription_funnel_statement_title',
    showProgress: false,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'statement',
        dimensions: {
          statementCards: statementDimension,
          statementWarning: statementWarningDimension,
        },
      },
    ],
    sideElements: [],
    secondaryNavigationUiElement: {
      type: 'statement-refusal',
      dimensions: {
        operatingZone: operatingZoneDimension,
        riskCarrierProducts: riskCarrierProductsDimension,
        activity: activityDimension,
      },
    },
    jumpToBottom: true,
    nextStepLabelKey: 'subscription_funnel_statement_confirm',
  },

  //#endregion
  //#region Company data when not done earlier
  {
    id: 'post-quote-siret-search',
    condition: {
      and: [
        { not: { or: [mrpwSelectedDimension, mrphSelectedDimension, esRcphSelectedDimension] } },
        { defined: placeSearchQueryDimension },
      ],
    },
    breadcrumbs: ['wrap_up', 'company'],
    title: 'Trouvons ensemble votre entreprise',
    showProgress: false,
    bodyElements: [
      {
        type: 'siret-search',
        cityFormat: 'wakam',
        searchText: 'Rechercher mon entreprise (nom, SIREN, SIRET)',
        cantFindText: 'Entreprise en cours de création  / je ne trouve pas mon entreprise',
        dimensions: {
          placeSearchQuery: placeSearchQueryDimension,
          placeSearchResults: placeSearchResultsDimension,
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          placeName: placeNameDimension,
          activityAddressStreet: activityAddressStreetDimension,
          activityAddressPostCode: activityAddressPostCodeDimension,
          activityAddressCity: activityAddressCityDimension,
          companyName: companyNameDimension,
          companyCreationDate: companyCreationDateDimension,
        },
        isSingleLineElement: { isSingleMini: false },
      },
    ],
    sideElements: [],
  },
  {
    id: 'post-quote-confirm-address',
    condition: {
      and: [{ not: { or: [mrpwSelectedDimension, mrphSelectedDimension] } }, { defined: activityAddressDimension }],
    },
    breadcrumbs: ['wrap_up', 'company'],
    titleKey: 'subscription_funnel_confirm_company_address_title',
    showProgress: false,
    bodyElements: [
      {
        type: 'address-map',
        dimension: activityAddressDimension,
        isSingleLineElement: { isSingleMini: false },
      },
      {
        type: 'siren',
        dimensions: {
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
          operatingZone: operatingZoneDimension,
        },
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: activityAddressCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', hideInBackoffice: true },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension, maxValue: companyCreationMaxDateDimension },
        layout: { width: 'narrow' },
        condition: { not: esRcphSelectedDimension },
      },
    ],
    sideElements: [],
  },
  {
    id: 'post-quote-add-address',
    condition: {
      and: [
        { not: { or: [mrpwSelectedDimension, mrphSelectedDimension] } },
        { not: { defined: activityAddressDimension } },
      ],
    },
    breadcrumbs: ['wrap_up', 'company'],
    titleKey: 'subscription_funnel_add_company_title',
    showProgress: false,
    bodyElements: [
      {
        type: 'siren',
        dimensions: {
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
          operatingZone: operatingZoneDimension,
        },
        condition: { not: siretRequiredDimension },
      },
      {
        type: 'siret',
        dimensions: {
          siret: siretDimension,
          companyIdNumber: companyIdNumberDimension,
          companyInCreationAllowed: companyInCreationAllowedDimension,
        },
        condition: siretRequiredDimension,
      },
      {
        type: 'string',
        dimension: activityAddressStreetDimension,
      },
      {
        type: 'postcode',
        dimension: activityAddressPostCodeDimension,
        layout: { width: 'narrow' },
        displayValues: {
          hint: 'Domiciliación de su establecimiento únicamente en España.',
        },
      },
      {
        type: 'string',
        dimension: activityAddressCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator' },
      {
        type: 'string',
        dimension: companyNameDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'calendar-date',
        dimensions: { value: companyCreationDateDimension, maxValue: companyCreationMaxDateDimension },
        layout: { width: 'narrow' },
        condition: { not: esRcphSelectedDimension },
      },
    ],
    sideElements: [],
  },
  //#endregion
  //#region End of funnel
  {
    id: 'additional-personal-data',
    breadcrumbs: ['wrap_up', 'identity'],
    titleKey: 'subscription_funnel_additional_personal_data_title',
    showProgress: false,
    hideInBackoffice: true,
    bodyElements: [
      { type: 'subtitle', textKey: 'subscription_funnel_additional_personal_data_subtitle' },
      { type: 'string', dimension: firstNameDimension, layout: { width: 'narrow' } },
      { type: 'string', dimension: lastNameDimension, layout: { width: 'narrow' } },
      { type: 'email', dimension: emailDimension, layout: { width: 'narrow' }, hideInBackoffice: true },
      { type: 'phone', dimension: phoneDimension, layout: { width: 'narrow' }, hideInBackoffice: true },
      {
        type: 'boolean',
        dimension: personalAddressIsNotActivityAddress,
        variant: 'checkbox',
        displayKeys: { label: 'subscription_funnel_additional_personal_data_address_different' },
        condition: { not: { or: [mutaSelectedDimension, esRcphSelectedDimension] } },
      },
      {
        type: 'separator',
        condition: personalAddressIsNotActivityAddress,
      },
      {
        type: 'subtitle',
        text: 'Adresse personnelle',
        condition: personalAddressIsNotActivityAddress,
      },
      {
        type: 'string',
        dimension: personalAddressStreetDimension,
        condition: personalAddressIsNotActivityAddress,
      },
      {
        type: 'postcode',
        condition: {
          and: [
            {
              not: mutaSelectedDimension,
            },
            personalAddressIsNotActivityAddress,
          ],
        },
        dimension: personalAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'disabled-string-dimension',
        condition: {
          and: [mutaSelectedDimension, personalAddressIsNotActivityAddress],
        },
        dimension: personalAddressPostCodeDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'string',
        dimension: personalAddressCityDimension,
        layout: { width: 'narrow' },
        condition: personalAddressIsNotActivityAddress,
      },
      {
        type: 'readonly-current-country',
        dimensions: { operatingZone: operatingZoneDimension },
        layout: { width: 'narrow' },
        condition: personalAddressIsNotActivityAddress,
      },
      { type: 'separator', condition: mutaSelectedDimension },
      { type: 'subtitle', condition: mutaSelectedDimension, text: 'Votre lieu de naissance' },
      {
        type: 'string',
        condition: mutaSelectedDimension,
        dimension: birthCityDimension,
        layout: { width: 'narrow' },
      },
      {
        type: 'country',
        condition: mutaSelectedDimension,
        dimension: birthCountryDimension,
        layout: { width: 'narrow' },
      },
      { type: 'separator', condition: mutaSelectedDimension },
      { type: 'subtitle', condition: mutaSelectedDimension, text: 'Votre régime social' },
      {
        type: 'disabled-muta-social-security-regime-dimension',
        condition: mutaSelectedDimension,
        dimension: mutaSocialSecurityRegimeDimension,
      },
      {
        type: 'social-security-organism-number',
        condition: mutaSelectedDimension,
        dimensions: {
          mutaSocialSecurityOrganismNumber: mutaSocialSecurityOrganismNumberDimension,
          mutaRegime: mutaSocialSecurityRegimeDimension,
        },
        layout: { width: 'narrow' },
      },
      {
        type: 'social-security-number',
        condition: mutaSelectedDimension,
        dimensions: { mutaSocialSecurityNumber: mutaSocialSecurityNumberDimension, birthDate: birthDateDimension },
        layout: { width: 'narrow' },
      },
      { type: 'separator', condition: { and: [mutaSelectedDimension, mutaHasBeneficiariesDimension] } },
      {
        type: 'subtitle',
        hideInBackoffice: true,
        condition: { and: [mutaSelectedDimension, mutaHasBeneficiariesDimension] },
        text: 'Bénéficiaires de la mutuelle santé',
      },
      {
        hideInBackoffice: true,
        condition: { and: [mutaSelectedDimension, mutaHasBeneficiariesDimension] },
        type: 'finalize-beneficiaries',
        dimensions: {
          mutaBeneficiaries: mutaBeneficiariesDimension,
          mutaSocialSecurityNumber: mutaSocialSecurityNumberDimension,
        },
      },
    ],
    sideElements: [],
  },
  {
    id: 'muta-bank-account',
    condition: mutaSelectedDimension,
    breadcrumbs: ['wrap_up', 'identity'],
    title: 'Vos coordonnées bancaires de remboursement',
    hideInBackoffice: true,
    showProgress: false,
    bodyElements: [{ type: 'bank-account', dimension: mutaReimbursementBankAccountDimension }],
  },
  {
    id: 'confirm-documents-locking',
    condition: { and: [{ notEmpty: neededSubscriptionDocumentsDimension }, { not: documentsLockConfirmedDimension }] },
    hideInBackoffice: true,
    breadcrumbs: ['wrap_up', 'payment'],
    title: 'Vos documents',
    showProgress: false,
    bodyElements: [
      {
        type: 'boolean',
        variant: 'checkbox-with-title',
        displayValues: {
          label:
            'La souscription de votre contrat est soumise à la validation de vos documents. Votre souscription sera verrouilée tant que vos documents n’auront pas été validés.',
          hint: 'Je confirme vouloir verrouiller ma souscription et envoyer mes documents pour validation',
        },
        dimension: documentsLockConfirmedDimension,
      },
    ],
    sideElements: defaultSideElements,
  },
  {
    id: 'locked',
    condition: { and: [{ notEmpty: neededSubscriptionDocumentsDimension }, documentsLockConfirmedDimension] },
    hideInBackoffice: true,
    breadcrumbs: ['wrap_up', 'payment'],
    showProgress: false,
    bodyElements: [
      {
        type: 'locked',
        dimensions: {
          startDate: startDateDimension,
          commitment: commitmentDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          discount: discountDimension,
          offerInformation: offerInformationDimension,
          quote: quoteDimension,
          generalTermsGroups: generalTermsGroupsDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          rcdaQuote: rcdaQuoteDimension,
          neededSubscriptionDocuments: neededSubscriptionDocumentsDimension,
          idDocument: idDocumentDimension,
          immatriculationDocument: immatriculationDocumentDimension,
          invoicesDocument: invoicesDocumentDimension,
          otherDocument: otherDocumentDimension,
          proformaInvoiceDocument: proformaInvoiceDocumentDimension,
          ribDocument: ribDocumentDimension,
          infoReportDocument: infoReportDocumentDimension,
          experienceDocument: experienceDocumentDimension,
          experiencePizzeriaDocument: experiencePizzeriaDocumentDimension,
          globalDocumentNote: globalDocumentNoteDimension,
          decennaleCertificateDocument: decennaleCertificateDocumentDimension,
          yearlyAccountsDocument: yearlyAccountsDocumentDimension,
          rcdaSelected: rcdaSelectedDimension,
          operatingZone: operatingZoneDimension,
        },
      },
    ],
  },
  {
    id: 'checkout',
    breadcrumbs: ['wrap_up', 'payment'],
    showProgress: false,
    hideInBackoffice: true,
    bodyElements: [
      {
        type: 'checkout',
        dimensions: {
          startDate: startDateDimension,
          commitment: commitmentDimension,
          discount: discountDimension,
          offerInformation: offerInformationDimension,
          paymentRecurrence: paymentRecurrenceDimension,
          forbiddenMonthlyPayment: forbiddenMonthlyPaymentDimension,
          expectedFirstPaymentDate: expectedFirstPaymentDateDimension,
          quote: quoteDimension,
          generalTermsGroups: generalTermsGroupsDimension,
          yearlyCommitmentHasDiscount: yearlyCommitmentHasDiscountDimension,
          riskCarrierProducts: riskCarrierProductsDimension,
          rcdaQuote: rcdaQuoteDimension,
          firstName: firstNameDimension,
          lastName: lastNameDimension,
          email: emailDimension,
          address: personalAddressStreetDimension,
          postCode: personalAddressPostCodeDimension,
          city: personalAddressCityDimension,
          operatingZone: operatingZoneDimension,
        },
      },
    ],
  },

  //#endregion
]

export const subscriptionUiStepsById = Object.fromEntries(subscriptionUiSteps.map((step) => [step.id, step])) as Record<
  SubscriptionStepId,
  SubscriptionUiStep
>

export const subscriptionUiStepsIndicesById = Object.fromEntries(
  subscriptionUiSteps.map((step, stepIndex) => [step.id, stepIndex]),
) as Record<SubscriptionStepId, number>

export function isSubscriptionStepId(value: unknown): value is SubscriptionStepId {
  return typeof value === 'string' && Object.hasOwn(subscriptionUiStepsById, value)
}

export const subscriptionStepDimensionsByStepId = Object.fromEntries(
  subscriptionUiSteps.map((step) => {
    const dimensions: {
      dimension: AbstractDimension<string, unknown>
      condition?: SubscriptionCondition | undefined
    }[] = [...trackedDimensions.map((dimension) => ({ dimension }))]

    for (const element of iterateStepUiElements(step)) {
      for (const elementDimension of getElementDimensions(element)) {
        if (!dimensions.some((seenDimension) => seenDimension.dimension.name === elementDimension.name)) {
          dimensions.push({ dimension: elementDimension, condition: element.condition })
        }
      }
    }
    return [step.id, dimensions]
  }),
) as unknown as Record<
  SubscriptionStepId,
  { dimension: AbstractDimension; condition?: SubscriptionCondition | undefined }[]
>

export const subscriptionStepDimensionNamesAndConditionsByStepId = Object.fromEntries(
  Object.entries(subscriptionStepDimensionsByStepId).map(([stepId, dimensions]) => {
    return [stepId, dimensions.map((dimension) => ({ name: dimension.dimension.name, condition: dimension.condition }))]
  }),
) as Record<SubscriptionStepId, { name: string; condition?: SubscriptionCondition | undefined }[]>

export function isElementSingleClick(element: SubscriptionUiElement): boolean {
  switch (element.type) {
    case 'activity-search-main':
    case 'activity-category-search':
    case 'siret-search':
    case 'checkout':
      return true
    case 'choice':
      return element.variant === 'one-click-submit-rows' || element.variant === 'one-click-occupation-status-grid'
    case 'boolean':
      return element.variant === 'yes-no-buttons' && !element.disableSubmit
    case 'price-card':
      return element.showSubscribeButton
    default:
      return false
  }
}

export function canNavigate(stepId: SubscriptionStepId): boolean {
  return stepId !== 'locked'
}

export const isStepSingleClick = Object.fromEntries(
  subscriptionUiSteps.map((step) => {
    let singleClick = false
    for (const element of iterateStepUiElements(step)) {
      if (isElementSingleClick(element)) {
        singleClick = true
        break
      }
    }
    return [step.id, singleClick]
  }),
) as Record<SubscriptionStepId, boolean>

export function* iterateStepUiElements(step: SubscriptionUiStep): IterableIterator<SubscriptionUiElement> {
  const elements: SubscriptionUiElement[] = []
  if ('subtitleElement' in step) yield step.subtitleElement
  if ('secondaryNavigationUiElement' in step) yield step.secondaryNavigationUiElement
  if ('bodyElements' in step) yield* step.bodyElements
  if ('sideElements' in step) yield* step.sideElements
  if ('details' in step) yield* Object.values(step.details).flat()
  return elements
}

export function getElementDimensions(element: SubscriptionUiElement): AbstractDimension[] {
  const dimensions: AbstractDimension[] = []
  if ('dimension' in element) dimensions.push(element.dimension)
  if ('dimensions' in element) dimensions.push(...Object.values(element.dimensions))
  if ('condition' in element) dimensions.push(...getConditionDimensions(element.condition))
  if ('tracking' in element) dimensions.push(...Object.values(element.tracking.dimensions))
  return dimensions
}

export function isLastStep(stepId: SubscriptionStepId): boolean {
  return stepId === subscriptionUiSteps.at(-1)?.id
}

/**
 * Obtain a number indicating progress in the subscription funnel.
 *
 * Steps have a progress between 0 and 1, higher number meaning they are
 * further in the subscription process.
 *
 * 0 indicates that we are at the very beginning
 * 1 indicates that the part where we measure progress is finished and no progress bar should be displayed
 */
export function getStepProgress(stepId: SubscriptionStepId): number {
  return getStepsProgress()[stepId]
}

function getStepsProgress(): { readonly [key in SubscriptionStepId]: number } {
  if (stepsProgress === undefined) stepsProgress = computeStepsProgress()
  return stepsProgress
}

let stepsProgress: { readonly [key in SubscriptionStepId]: number } | undefined = undefined

function computeStepsProgress(): { readonly [key in SubscriptionStepId]: number } {
  const stepProgress: {
    [key in SubscriptionStepId]?: number | undefined
  } = {}
  const indexOfFirstStepWithoutProgress = subscriptionUiSteps.findIndex((step) => !step.showProgress)

  subscriptionUiSteps
    .slice(0, indexOfFirstStepWithoutProgress)
    .forEach(
      (step, stepIndex) =>
        (stepProgress[step.id] =
          epsilon + funnelProgressStart + (1 - funnelProgressStart) * (stepIndex / indexOfFirstStepWithoutProgress)),
    )

  subscriptionUiSteps.slice(indexOfFirstStepWithoutProgress).forEach((step) => (stepProgress[step.id] = 1))

  return stepProgress as { readonly [key in SubscriptionStepId]: number }
}

/**
 * Very small number added to avoid floating point imprecisions to display a progress bar when the progress
 * that should be at 1 falls just below
 */
const epsilon = 0.00001

/**
 * We don't start at zero, for aesthetic reasons
 */
const funnelProgressStart = 0.1

/**
 * Progress displayed before entering the funnel in the search step
 */
export const preFunnelProgress = funnelProgressStart / 2
