import type { Product } from '@orus.eu/product'
import type { AbstractDimension, TypeOfDimension } from './abstract-dimension'
import { StringsWithDataEnumDimension } from './strings-with-data-enum-dimension'

export const mutaPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mutaPricingVersion',
  displayValues: { name: 'Version du prix de la mutuelle Acheel' },
  entries: [['muta-1.0-2024-11-19', { label: 'Version 1' }]],
} as const)

export type MutaPricingVersion = TypeOfDimension<typeof mutaPricingVersionDimension>

export const rcdaPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'rcdaPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile décennale Axeria' },
  entries: [['rcda-1.0-2024-11-19', { label: 'Version 1' }]],
} as const)

export type RcdaPricingVersion = TypeOfDimension<typeof rcdaPricingVersionDimension>

export const mrphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mrphPricingVersion',
  displayValues: { name: 'Version du prix de la multirisque Hiscox' },
  entries: [
    ['mrph-1.0-2024-11-19', { label: 'Version 1' }],
    ['mrph-1.1-2024-11-22', { label: 'Version 1.1 - MaJ CatNat' }],
  ],
} as const)

export type MrphPricingVersion = TypeOfDimension<typeof mrphPricingVersionDimension>

export const rcphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'rcphPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile Hiscox' },
  entries: [
    ['rcph-1.0-2024-11-19', { label: 'Version 1' }],
    ['rcph-1.1-2024-11-22', { label: 'Version 1.1 - MaJ CatNat' }],
  ],
} as const)

export type RcphPricingVersion = TypeOfDimension<typeof rcphPricingVersionDimension>

export const mrpwPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'mrpwPricingVersion',
  displayValues: { name: 'Version du prix de la multirisque Wakam' },
  entries: [
    ['mrpw-1-2024-11-19', { label: 'MRPW v1 / (Wakam API v2) - MRPW Rest v1' }],
    ['mrpw-1.2-2024-11-19', { label: 'MRPW v1.2 (Wakam API v3) - MRPW Rest v2' }],
    ['mrpw-2-2024-11-19', { label: 'MRPW v2 (Wakam API v6) - Retailers' }],
  ],
} as const)

export type MrpwPricingVersion = TypeOfDimension<typeof mrpwPricingVersionDimension>

export const esRcphPricingVersionDimension = new StringsWithDataEnumDimension({
  name: 'esRcphPricingVersion',
  displayValues: { name: 'Version du prix de la responsabilité civile Hiscox Espagne' },
  entries: [['es-rcph-1.0-2024-11-19', { label: 'Version 1' }]],
} as const)

export type EsRcphPricingVersion = TypeOfDimension<typeof esRcphPricingVersionDimension>

export type PricingVersion =
  | MutaPricingVersion
  | RcdaPricingVersion
  | MrphPricingVersion
  | RcphPricingVersion
  | MrpwPricingVersion
  | EsRcphPricingVersion

export const pricingVersionDimensionsByProduct = {
  muta: mutaPricingVersionDimension,
  rcda: rcdaPricingVersionDimension,
  mrph: mrphPricingVersionDimension,
  rcph: rcphPricingVersionDimension,
  mrpw: mrpwPricingVersionDimension,
  'es-rcph': esRcphPricingVersionDimension,
} as const satisfies Record<Product, AbstractDimension>
