import styled from '@emotion/styled'
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { esES, frFR, type PickersInputLocaleText } from '@mui/x-date-pickers/locales'
import {
  calendarDateGt,
  calendarDateLt,
  getStartOfCalendarDate,
  PARIS,
  type CalendarDate,
} from '@orus.eu/calendar-date'
import type { Language } from '@orus.eu/translations'
import { DateTime } from 'luxon'
import { memo, useCallback, useState } from 'react'
import { borderStroke, colorTokens, shadow, spacing } from '../../../foundation'
import { useLanguage, useTranslate } from '../../../localization'
import { Icon } from '../../atoms'
import { cssPropsPerScreenVariantPerTextVariant } from '../../atoms/text'

type DatePickerSize = 'small' | 'large'

export type DatePickerProps = {
  value?: CalendarDate | null
  onChange: (value: CalendarDate | null) => void
  minDate?: CalendarDate
  maxDate?: CalendarDate
  maxDateExplanation?: string
  size?: DatePickerSize
  error?: boolean
  errorMessage?: string
  disabled?: boolean
  autoFocus?: boolean
  className?: string
  fullWidth?: boolean
}

const dateFormat = 'dd/MM/yyyy'
const dateFormatDisplays: Record<Language, string> = {
  fr: 'JJ/MM/AAAA',
  es: 'DD/MM/AAAA',
}

const datePickerDimensionsPerSize: {
  [size in DatePickerSize]: { height: string }
} = {
  small: {
    height: '32px',
  },
  large: {
    height: '48px',
  },
}

const body2Typo = cssPropsPerScreenVariantPerTextVariant['body2']['desktop']

const pickersInputLocaleTexts: Record<Language, PickersInputLocaleText<DateTime>> = {
  fr: frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  es: esES.components.MuiLocalizationProvider.defaultProps.localeText,
}

const adapterLocales: Record<Language, string> = {
  fr: 'fr',
  es: 'es',
}

export const DatePicker = memo<DatePickerProps>(function DatePicker(props) {
  const {
    value,
    minDate,
    maxDate,
    maxDateExplanation,
    onChange,
    size = 'large',
    error,
    disabled,
    autoFocus,
    className,
  } = props

  const [validationActive, setValidationActive] = useState(false)
  const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(null)
  const language = useLanguage()
  const translate = useTranslate()

  const setValue = useCallback(
    (date: DateTime | null) => {
      // The DatePicker component from MUI may send invalid dates during typing.
      // Since CalendarDate is not supposed to store invalid values, we prefer to send null values to the upper component
      if (!date) {
        onChange(null)
        setInternalErrorMessage(null)
        return
      }

      if (!date.isValid) {
        onChange(null)
        setInternalErrorMessage(translate('datepicker_error_format', { format: dateFormatDisplays[language] }))
        return
      }

      const selectedCalendarDate: CalendarDate = {
        year: date.year,
        oneBasedMonth: date.month,
        oneBasedDay: date.day,
      }
      if (minDate && calendarDateLt(selectedCalendarDate, minDate)) {
        const minDateValue = DateTime.local(minDate.year, minDate.oneBasedMonth, minDate.oneBasedDay, 12)
        const formattedDate = minDateValue.toFormat(dateFormat)
        onChange(null)
        setInternalErrorMessage(translate('datepicker_error_too_soon', { date: formattedDate }))
        return
      }
      if (maxDate && calendarDateGt(selectedCalendarDate, maxDate)) {
        const maxDateValue = DateTime.local(maxDate.year, maxDate.oneBasedMonth, maxDate.oneBasedDay, 12)
        onChange(null)
        setInternalErrorMessage(
          maxDateExplanation ?? translate('datepicker_error_too_late', { date: maxDateValue.toFormat(dateFormat) }),
        )
        return
      }

      onChange(selectedCalendarDate)
      setInternalErrorMessage(null)
    },
    [language, maxDate, maxDateExplanation, minDate, onChange, translate],
  )

  const errorMessage = validationActive ? internalErrorMessage || props.errorMessage : null

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={adapterLocales[language]}
      localeText={pickersInputLocaleTexts[language]}
    >
      <StyledDatePicker
        orusSize={size}
        error={error}
        value={value ? DateTime.local(value.year, value.oneBasedMonth, value.oneBasedDay, 12) : null}
        onChange={(value) => setValue(value)}
        minDate={minDate ? getStartOfCalendarDate(minDate, PARIS) : undefined}
        maxDate={maxDate ? getStartOfCalendarDate(maxDate, PARIS) : undefined}
        disabled={disabled}
        className={className}
        slots={{ openPickerIcon: CustomOpenIcon }}
        slotProps={{
          textField: {
            fullWidth: true,
            error,
            helperText: errorMessage,
            onBlur: () => setValidationActive(true),
            autoFocus,
            size: size === 'large' ? 'medium' : 'small',
            placeholder: dateFormatDisplays[language],
          },
        }}
      />
    </LocalizationProvider>
  )
})

const CustomOpenIcon = memo(function CustomOpenIcon(): JSX.Element {
  return <Icon icon="calendar-regular" size="30" />
})

const StyledDatePicker = styled(MuiDatePicker)<{ orusSize: DatePickerSize; error?: boolean }>`
  & > .MuiOutlinedInput-root {
    box-shadow: ${shadow.bottom['05']};
    padding-right: ${spacing['40']};

    & .MuiIconButton-root:hover {
      background-color: inherit;
    }

    & fieldset {
      border: ${({ error }) => (error ? `${borderStroke['30']} solid` : '')};
      border-color: ${({ error }) => (error ? `${colorTokens['color-stroke-danger']} !important` : '')};
      padding: 0;
    }

    &.Mui-disabled {
      box-shadow: none;
      background-color: ${colorTokens['color-bg-base-disable']};

      & input {
        color: ${colorTokens['color-text-base-disable']};
      }

      & fieldset {
        border-color: ${colorTokens['color-stroke-base-disable']} !important;
      }
    }
  }

  & .MuiOutlinedInput-input {
    padding: 0 0 0 ${spacing['40']};
    height: ${({ orusSize }) => datePickerDimensionsPerSize[orusSize].height};
    ${body2Typo};
  }

  & input::placeholder {
    color: ${colorTokens['color-text-base-disable']};
    opacity: 1;
  }
`
