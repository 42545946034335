import { DOCUMENTATION_LANGUAGE, m, type FieldSpecification, type ValueDocumentation } from '@orus.eu/message'
import { success, type Result } from '@orus.eu/result'
import {
  AbstractDimension,
  dimensionValidationFailure,
  type DimensionOptions,
  type DimensionTrackingMessageFieldAdapter,
  type DimensionValidationProblem,
  type LooselyTypedValue,
} from './abstract-dimension.js'

export class StringsWithDataEnumDimension<
  NAME extends string,
  DATA,
  ENTRIES extends ReadonlyArray<readonly [string, DATA]>,
> extends AbstractDimension<NAME, ENTRIES[number][0]> {
  readonly type = 'strings-with-data-enum'
  readonly entries: ENTRIES
  readonly values: readonly ENTRIES[number][0][]
  readonly data: { [key in ENTRIES[number][0]]: ENTRIES[number][1] }

  constructor(options: DimensionOptions<NAME> & { entries: ENTRIES }) {
    super(options)
    this.entries = options.entries
    this.data = Object.fromEntries(this.entries) as { [key in ENTRIES[number][0]]: DATA }
    const values = options.entries.map((entry) => entry[0])
    this.values = values
  }

  override validateData(value: LooselyTypedValue): Result<ENTRIES[number][0], DimensionValidationProblem> {
    for (const entry of this.entries) {
      const validValue = entry[0]
      if (validValue === value) return success(validValue)
    }
    return dimensionValidationFailure(`Field ${this.name} has an invalid value`)
  }

  override readonly trackingMessageFieldAdapter: DimensionTrackingMessageFieldAdapter = {
    getTrackingFieldSpecification: (): FieldSpecification => {
      const valueDocumentation: ValueDocumentation<ENTRIES[number][0]> = {
        title: this.displayNames[DOCUMENTATION_LANGUAGE],
      }
      if (this.hints) {
        valueDocumentation.detailedExplanationMd = this.hints[DOCUMENTATION_LANGUAGE]
      }
      return m.nullable(
        m.enumWithoutValueDescription(
          valueDocumentation,
          Object.fromEntries(this.values.map((value) => [value, null])) as { [key in ENTRIES[number][0]]: null },
        ),
      )
    },
    convertStateValueToTrackingValue: (stateValue: ENTRIES[number][0] | null | undefined): string | null => {
      if (!stateValue) return null
      return stateValue ?? null
    },
  }
}

export function getStringsWithDataEnumDimensionEntriesKey<
  NAME extends string,
  DATA,
  ENTRIES extends ReadonlyArray<readonly [string, DATA]>,
>(stringsWithDataEnumDimension: StringsWithDataEnumDimension<NAME, DATA, ENTRIES>): ReadonlyArray<string> {
  return stringsWithDataEnumDimension.entries.map((entry) => entry[0])
}
