import {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  type ComponentProps,
  type FC,
  type KeyboardEvent,
  type ReactNode,
} from 'react'

import DocViewer, {
  DocViewerRenderers,
  type DocRenderer,
  type DocViewerRef,
  type IDocument,
} from '@cyntler/react-doc-viewer'

import { FlexColumn } from '../../components/atoms'

import styled from '@emotion/styled'
import heic2any from 'heic2any'
import { TransformComponent, TransformWrapper, useControls, type ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { colorTokens, spacing } from '../../foundation'

import { css } from '@emotion/react'
import { Button } from '../../components/button/button'
import { Spinner } from '../../components/spinner'
import { useAsyncCallback } from '../../hooks'
import { downloadFile, downloadFromOrus } from '../../lib/download'
import { DocViewerContext, useDocumentViewer } from './document-preview-context'

// Custom renderer for HEIC files

const convertHeic = async (uri: string) => {
  return heic2any({
    blob: await (await fetch(uri)).blob(),
    toType: 'image/jpeg',
  }) as Promise<Blob>
}

const HeicRenderer: DocRenderer = ({ mainState: { currentDocument, ...mainState }, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [document, setDocument] = useState<IDocument | null>(null)

  useEffect(() => {
    if (!currentDocument) return undefined

    setIsLoading(true)
    convertHeic(currentDocument.uri)
      .then((blob) => {
        setDocument({
          ...currentDocument,
          fileData: window.URL.createObjectURL(blob),
        })

        setIsLoading(false)
      })
      .catch(console.error)
  }, [currentDocument, setDocument])

  if (isLoading) return <Spinner />
  if (!document) return null

  return <ImageRenderer mainState={{ currentDocument: document, ...mainState }} {...props} />
}

HeicRenderer.fileTypes = ['heic', 'image/heic']
HeicRenderer.weight = 1

// Custom renderer for images with custom controls

const ImageControls = () => {
  const { zoomIn, zoomOut, centerView } = useControls()
  const { getScaleToFit, rotateClockwise, rotateCounterClockwise } = useDocumentViewer()

  return (
    <ButtonColumn>
      <Button ariaLabel="Zoom avant" icon="plus-regular" variant="secondary" onClick={() => zoomIn()} size="small" />
      <Button
        ariaLabel="Zoom arrière"
        icon="minus-regular"
        variant="secondary"
        onClick={() => zoomOut()}
        size="small"
      />
      <Button
        ariaLabel="Scale to fit"
        icon="arrows-left-right-regular"
        variant="secondary"
        onClick={() => centerView(getScaleToFit())}
        size="small"
      />
      <Button
        ariaLabel="Rotation horaire"
        icon="arrow-rotate-right-regular"
        variant="secondary"
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          rotateClockwise()
          return false
        }}
        size="small"
      />
      <Button
        ariaLabel="Rotation anti-horaire"
        icon="arrow-rotate-left-regular"
        variant="secondary"
        onClick={() => rotateCounterClockwise()}
        size="small"
      />
    </ButtonColumn>
  )
}

const ImageContainer = styled.div`
  position: relative;
  background-color: ${colorTokens['color-bg-neutral']};
  width: 100%;

  img,
  .react-transform-wrapper {
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
`

const ButtonColumn = styled(FlexColumn)`
  gap: ${spacing[30]};
  width: fit-content;
  top: ${spacing[40]};
  right: ${spacing[40]};
  position: absolute;
  z-index: 100;
`

const ImageRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const { imageRef, containerRef, zoomPanPinchRef, getScaleToFit } = useDocumentViewer()

  const scaleToFit = useCallback(() => {
    if (!zoomPanPinchRef?.current) return
    const scale = getScaleToFit()
    if (!scale) return
    zoomPanPinchRef.current.instance.setup.minScale = scale
    zoomPanPinchRef?.current?.centerView(scale)
  }, [getScaleToFit, zoomPanPinchRef])

  if (!currentDocument) return null

  return (
    <ImageContainer id="image-preview" ref={containerRef}>
      <TransformWrapper ref={zoomPanPinchRef} disablePadding onInit={scaleToFit} wheel={WHEEL_CONFIG}>
        <ImageControls />
        <TransformComponent>
          <img
            ref={imageRef}
            id="img"
            src={(currentDocument.fileData as string) ?? currentDocument.uri}
            onLoad={scaleToFit}
          />
        </TransformComponent>
      </TransformWrapper>
    </ImageContainer>
  )
}
ImageRenderer.fileTypes = ['png', 'image/png', 'jpg', 'jpeg', 'image/jpeg']
ImageRenderer.weight = 10

const WHEEL_CONFIG = { smoothStep: 0.003 } as const

const PdfRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const [frameSrc, setFrameSrc] = useState<string>()
  useEffect(() => {
    if (!currentDocument) return

    downloadFromOrus(currentDocument.uri, 'application/pdf')
      .then((blob) => {
        setFrameSrc(URL.createObjectURL(blob))
      })
      .catch((err) => console.error(err))
  }, [currentDocument])

  if (frameSrc) {
    return (
      <embed
        id="pdf-preview"
        css={css`
          height: 100%;
          width: 100%;
        `}
        src={frameSrc}
      />
    )
  }
  return undefined
}
PdfRenderer.fileTypes = ['pdf', 'application/pdf']
PdfRenderer.weight = 10

export const DocumentPreview: FC = function DocumentPreview(): ReactNode {
  const {
    containerRef,
    docViewerRef,
    activeDocument,
    onActiveDocumentChange,
    nextDocument,
    previousDocument,
    documents,
  } = useDocumentViewer()

  useEffect(() => {
    const upKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowUp') previousDocument()
    }
    const downKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowDown') nextDocument()
    }

    // @ts-expect-error for some weird reason typescript does not implement keydown events as KeyboardEvents
    document.addEventListener('keydown', upKeyPress)
    // @ts-expect-error for some weird reason typescript does not implement keydown events as KeyboardEvents
    document.addEventListener('keydown', downKeyPress)

    return () => {
      // @ts-expect-error for some weird reason typescript does not implement keydown events as KeyboardEvents
      document.removeEventListener('keydown', upKeyPress)
      // @ts-expect-error for some weird reason typescript does not implement keydown events as KeyboardEvents
      document.removeEventListener('keydown', downKeyPress)
    }
  }, [containerRef, nextDocument, previousDocument])

  return (
    <StyledDocViewer
      ref={docViewerRef}
      config={{ header: { disableHeader: true } }}
      documents={documents}
      activeDocument={activeDocument}
      onDocumentChange={onActiveDocumentChange}
      pluginRenderers={[...DocViewerRenderers, HeicRenderer, ImageRenderer, PdfRenderer]}
    />
  )
}

export const DocumentPreviewContext = memo<{
  /** The list of documents to preview */
  documents: IDocument[]
  /** currently active document state, if no change handler is given, it's used as initializer */
  activeDocument?: IDocument
  /** Called when the active document changes from within the docviewer, so it can be managed externally from the docviewer */
  onActiveDocumentChange?: (document: IDocument) => void
  children: ReactNode
}>(function DocumentPreviewContext({ documents, activeDocument, onActiveDocumentChange, children }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const docViewerRef = useRef<DocViewerRef>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const zoomPanPinchRef = useRef<ReactZoomPanPinchRef>(null)
  const rotationValue = useRef(0)
  const activeDocumentIndex = useRef(activeDocument ? documents.findIndex((doc) => doc.uri === activeDocument.uri) : 0)

  const [localActiveDocument, setLocalActiveDocument] = useState<IDocument | undefined>(activeDocument)

  const useLocalActiveDocument = onActiveDocumentChange === undefined

  const { uri, fileName, fileType } = (useLocalActiveDocument ? localActiveDocument : activeDocument) ?? {
    uri: '',
    fileName: '',
  }

  const resolvedOnActiveDocumentChange = useLocalActiveDocument ? setLocalActiveDocument : onActiveDocumentChange
  const contextValueAPI = {
    imageRef,
    containerRef,
    docViewerRef,
    zoomPanPinchRef,
    documents,
    activeDocument: useLocalActiveDocument ? localActiveDocument : activeDocument,
    onActiveDocumentChange: resolvedOnActiveDocumentChange,
    nextDocument: useCallback(() => {
      activeDocumentIndex.current = (activeDocumentIndex.current + 1) % documents.length
      const nextDocument = documents[activeDocumentIndex.current]
      if (nextDocument) resolvedOnActiveDocumentChange(nextDocument)
    }, [documents, resolvedOnActiveDocumentChange]),
    previousDocument: useCallback(() => {
      activeDocumentIndex.current = (activeDocumentIndex.current - 1 + documents.length) % documents.length
      const previousDocument = documents[activeDocumentIndex.current]
      if (previousDocument) resolvedOnActiveDocumentChange(previousDocument)
    }, [documents, resolvedOnActiveDocumentChange]),
    rotateClockwise: () => {
      if (!imageRef?.current) return
      const newRotation = (rotationValue.current + 90) % 360
      imageRef.current!.style!.transform = `rotate(${newRotation}deg)`
      rotationValue.current = newRotation
    },
    rotateCounterClockwise: () => {
      if (!imageRef?.current) return
      const newRotation = (rotationValue.current - 90) % 360
      imageRef.current!.style!.transform = `rotate(${newRotation}deg)`
      rotationValue.current = newRotation
    },
    downloadDocument: useAsyncCallback(
      () => downloadFile(uri, fileName ?? 'document', fileType),
      [fileName, fileType, uri],
    ),
    getScaleToFit: useCallback(() => {
      if (containerRef.current && imageRef.current) {
        const wrapperWidth = containerRef.current.clientWidth
        const wrapperHeight = containerRef.current.clientHeight

        const contentWidth = imageRef.current.clientWidth
        const contentHeight = imageRef.current.clientHeight

        const widthScale = wrapperWidth / contentWidth
        const heightScale = wrapperHeight / contentHeight

        const scale = widthScale < heightScale ? widthScale : heightScale

        return scale
      }
      return 1
    }, [imageRef, containerRef]),
  } satisfies ComponentProps<typeof DocViewerContext.Provider>['value']

  return <DocViewerContext.Provider value={contextValueAPI}>{children}</DocViewerContext.Provider>
})

const StyledDocViewer = styled(DocViewer)`
  & img {
    max-width: 100%;
  }

  & #pdf-download,
  #pdf-zoom-out,
  #pdf-zoom-in,
  #pdf-zoom-reset,
  #pdf-toggle-pagination,
  .textLayer {
    display: none;
  }

  & #proxy-renderer {
    height: 100%;
  }
`
