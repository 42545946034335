import type { OfferType, PolicyOffer } from './quote-offer-information-dimension.js'

export const offerNames: { [key in OfferType]: string } = {
  protectionJuridique: 'Protection juridique',
  rcp: 'Responsabilité civile professionnelle',
  avantagePlus: 'Autres garanties incluses',
  assuranceDommagesBiens: 'Assurance dommages aux biens',
  exceptionalEvents: 'Événements exceptionnels',
  assistance: 'Assistance',
  cyber: 'Cyber',
  pj: 'Protection juridique',
  decennialCivilLiability: 'Responsabilité civile décennale',
  nonDecennialCivilLiability: 'Responsabilité civile hors décennale',
  equipmentsMaterials: 'Équipements et matériels',
  esRcph: 'Responsabilidad civil profesional',
  esRcphGeneral: 'Responsabilidad civil general',
}

export const offersDescriptions: {
  [offer in OfferType]: PolicyOffer
} = {
  rcp: { type: 'rcp', name: offerNames['rcp'] },
  avantagePlus: { type: 'avantagePlus', name: offerNames['avantagePlus'] },
  protectionJuridique: {
    type: 'protectionJuridique',
    name: offerNames['protectionJuridique'],
  },
  assuranceDommagesBiens: {
    type: 'assuranceDommagesBiens',
    name: offerNames['assuranceDommagesBiens'],
  },
  exceptionalEvents: {
    type: 'exceptionalEvents',
    name: offerNames['exceptionalEvents'],
  },
  assistance: { type: 'assistance', name: offerNames['assistance'] },
  cyber: { type: 'cyber', name: offerNames['cyber'] },
  pj: { type: 'pj', name: offerNames['pj'] },
  decennialCivilLiability: {
    type: 'decennialCivilLiability',
    name: offerNames['decennialCivilLiability'],
  },
  nonDecennialCivilLiability: {
    type: 'nonDecennialCivilLiability',
    name: offerNames['nonDecennialCivilLiability'],
  },
  equipmentsMaterials: {
    type: 'equipmentsMaterials',
    name: offerNames['equipmentsMaterials'],
  },
  esRcph: {
    type: 'esRcph',
    name: offerNames['esRcph'],
  },
  esRcphGeneral: {
    type: 'esRcphGeneral',
    name: offerNames['esRcphGeneral'],
  },
}
